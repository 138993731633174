import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const ModalWrapper = styled(Modal)`
  color: #1D1D1D;

  .modal {
    &-dialog {
      margin: 8rem auto;

      @media (max-width: 575px) {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        width: 100%;
      }
    }

    &-content {
      border-radius: 10px;

      @media (max-width: 575px) {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
    }

    &-header {
      background-color: #ECF4F4;
      padding: 10px 10px 7px 14px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;

      .btn-close {
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;
      }

      @media (max-width: 575px) {
        background-color: transparent;
        padding: 30px 30px 10px 30px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border: none;
      }
    }

    &-title {
      font-size: 13px;
      line-height: 15px;
      color: #04B3C0;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-body {
      padding: 15px 22px;

      @media (max-width: 575px) {
        padding: 5px 30px;
      }
    }

    &-footer {
      justify-content: flex-start;
      padding: 5px 22px 25px 22px;
      border: none;

      * {
        margin: 0;
      }

      @media (max-width: 575px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 20px 30px 35px 30px;

        .btn {
          font-size: 22px !important;
          line-height: 22px !important;
          padding: 15px 30px;
          border-radius: 60px;
        }
      }
    }
  }

  .form {
    &-text {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #1D1D1D;
      float: right;
    }
  }

  &.modal-xs {
    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 450px;
      }
    }
  }

  &.modal-md {
    .modal-dialog {
      @media (min-width: 768px) {
        max-width: 768px;
      }
    }
  }

  &.top {
    .modal-dialog {
      @media (max-width: 575px) {
        top: 0;
      }
    }
  }

  &.center {
    .modal-dialog {
      @media (max-width: 575px) {
        top: 50%;
        bottom: auto;
        left: 27px;
        width: calc(100% - 54px);
        transform: translateY(-50%) !important;
      }
    }
  }
`;

export const ConfirmModalWrapper = styled(Modal)`
  color: #1D1D1D;

  .modal {
    &-dialog {
      max-width: 300px;
      margin: 8rem auto;

      @media (max-width: 575px) {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        max-width: 500px;
        width: 100%;
      }
    }

    &-content {
      border-radius: 10px;
    }

    &-header {
      background-color: #ECF4F4;
      padding: 10px 10px 7px 14px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;

      .btn-close {
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;
      }

      @media (max-width: 575px) {
        display: none;
      }
    }

    &-title {
      font-size: 13px;
      line-height: 15px;
      color: #04B3C0;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-body {
      padding: 36px 39px;

      @media (max-width: 575px) {
        padding: 44px 30px 34px;
      }
    }

    &-message {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #1D1D1D;

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 32px;
        color: #000;
      }
    }

    &-footer {
      justify-content: space-evenly;
      padding: 0px 25px 22px;
      border: none;

      .btn {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        margin: 0 10px;
      }

      @media (max-width: 575px) {
        padding: 0 24px 46px;

        .btn {
          font-size: 22px !important;
          line-height: 22px !important;
          padding: 15px 24px;
          border-radius: 60px;
        }
      }
    }
  }
`;
