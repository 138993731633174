import { useEffect, useRef } from 'react';
import { loadScript } from 'utils/scripts';
import { ELoadableScripts, EGoogleComponentTypes } from 'interfaces';

declare global {
  interface Window {
    google: any;
  }
}

const useGoogleMaps = (
  onUpdate: (updateObj: any) => void,
  addressMap?: Record<EGoogleComponentTypes, string>
) => {
  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handlePlaceSelect = async () => {
    const addressObject = autoComplete.getPlace();
    if (!addressMap) {
      onUpdate(addressObject.formatted_address);
    } else {
      const addressComponents = addressObject.address_components;
      const updateObj = {};
      if (addressComponents) {
        addressComponents.forEach((component) => {
          const type = component.types[0];
          const addressKey = addressMap[type];
          if (addressKey) {
            updateObj[addressKey] = updateObj[addressKey]
              ? updateObj[addressKey] + ' ' + component.long_name
              : component.long_name;
          }
        });
        onUpdate(updateObj);
      }
    }
  };

  const handleScriptLoad = (autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'us' } }
    );
    autoComplete.setFields(['address_components', 'formatted_address']); // specify what properties we will get from API
    // add a listener to handle when the place is selected

    autoComplete.addListener('place_changed', () => {
      autoCompleteRef.current.focus();
      handlePlaceSelect();
    });
  };

  useEffect(() => {
    loadScript(
      ELoadableScripts.GoogleMaps,
      () => handleScriptLoad(autoCompleteRef)
    );
    return () => {
      window.google.maps.event.clearInstanceListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { autoCompleteRef };
};

export default useGoogleMaps;
