import { EApiRoutes, EQueryKeys,
  Interactions } from 'interfaces';
import { createDefaultRequests } from 'data/utils';

export default createDefaultRequests
  <Interactions.IShape>(
    EQueryKeys.Interactions,
    EApiRoutes.Interactions,
  );

export const chatData = [
  {
    date: '21/06/2021, 12:15 PM',
    type: 'voicio',
    message: 'John, Jennifer Smith wants to know if you replace flapper valves?'
  },
  {
    date: '21/06/2021, 12:15 PM',
    type: 'user',
    message: 'Yes, I can. Ask if they are rubber or plastic.'
  },
  {
    date: '21/06/2021, 12:15 PM',
    type: 'voicio',
    message: 'Okay, thanks!'
  },
  {
    date: '20/02/2022, 01:15 PM',
    type: 'user',
    message: 'Hello! Can Isaac be here earlier tomorrow?'
  },
  {
    date: '20/02/2022, 01:15 PM',
    type: 'voicio',
    message: 'Hello, Jennifer! Yes, Isaac will be there, 9am tomorrow.'
  }
];
