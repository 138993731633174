import styled from 'styled-components/macro';
import CodeInput from 'react-verification-code-input';

export const CodeInputWrapper = styled(CodeInput)`
  display: block;
  width: 100% !important;
  padding: 0 20px;
  text-align: center;

  > div {
    display: flex;
    justify-content: space-around;
    min-width: 250px;

    > input {
      font-family: 'Roboto';
      border: none !important;
      border-bottom: 2px solid #08b6c2 !important;
      border-radius: 0 !important;
      margin: 0 4px;
    }
  }
`;
