import { EApiRoutes, VReceptionist, IBusinessHours, EQueryKeys } from 'interfaces';
import { createDefaultRequests } from 'data/utils';

import AvatarIcon from 'assets/image/pages/app/user-actions-avatar.png';
import CallAudio from 'assets/audio/example.mp3';

export const businessHoursEndpoints = createDefaultRequests
  <IBusinessHours>(
    EQueryKeys.BusinessHours,
    EApiRoutes.BusinessHours,
    EApiRoutes.BusinessHoursDetail
  );

export const greetingEndpoints = createDefaultRequests
  <VReceptionist.IGreeting>(
    EQueryKeys.Greetings,
    EApiRoutes.Greetings
  );

export const callLogsEndpoint = createDefaultRequests
  <VReceptionist.ICallLog>(
    EQueryKeys.CallLogs,
    EApiRoutes.CallLog
  );

export const aiVoicesEndpoint = createDefaultRequests(
  EQueryKeys.AiVoices,
  EApiRoutes.AiVoices
);


export const aiVoicesTableData = [
  {
    name: 'A.I Voices 001',
  },
  {
    name: 'A.I Voices 002',
  },
  {
    name: 'A.I Voices 003',
  },
  {
    name: 'A.I Voices 004',
  },
  {
    name: 'A.I Voices 005',
  },
  {
    name: 'A.I Voices 006',
  },
  {
    name: 'A.I Voices 007',
  },
  {
    name: 'A.I Voices 008',
  },
  {
    name: 'A.I Voices 009',
  },
  {
    name: 'A.I Voices 010',
  },
  {
    name: 'A.I Voices 011',
  },
  {
    name: 'A.I Voices 012',
  },
];

export const personalGreetingTableData = [
  {
    name: 'John greeting 001',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 002',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 003',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 004',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 005',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 006',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 007',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 008',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 009',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 010',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 011',
    date: '21/06/2021 at 12:15 PM',
  },
  {
    name: 'John greeting 012',
    date: '21/06/2021 at 12:15 PM',
  },
];

export const callLogsTableData = [
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 at 12:15 PM',
    type: 'incoming-call',
    transcript: '',
    audio: CallAudio,
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 at 12:15 PM',
    type: 'email',
    transcript: '',
    text: 'Hello there\nCan I ask you some questions?',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 at 12:15 PM',
    type: 'email',
    transcript: '',
    text: 'Hello there\nCan I ask you some questions?',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 at 12:15 PM',
    type: 'incoming-call',
    transcript: '',
    audio: CallAudio,
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 at 12:15 PM',
    type: 'email',
    transcript: '',
    text: 'Hello there\nCan I ask you some questions?',
  },
];