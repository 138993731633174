import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components';
import { ConfirmModalWrapper } from './style';

type Props = {
  className?: string,
  show: boolean,
  onHide: () => void,
  onSubmit: () => void,
  title: string,
  message: string,
  showCancelBtn?: boolean,
  cancelBtnLabel?: string,
  successBtnLabel?: string,
}
const ConfirmModal: React.FC<Props> = ({
  title,
  message,
  showCancelBtn = true,
  cancelBtnLabel = 'Cancel',
  successBtnLabel = 'Submit',
  onHide,
  onSubmit,
  ...props
}) => (
  <ConfirmModalWrapper onHide={onHide} {...props}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-message">
        {message}
      </div>
    </Modal.Body>
    <Modal.Footer>
      {showCancelBtn && (
        <Button
          variant="danger"
          round
          onClick={onHide}
        >
          {cancelBtnLabel}
        </Button>
      )}
      <Button round onClick={onSubmit}>{successBtnLabel}</Button>
    </Modal.Footer>
  </ConfirmModalWrapper>
);

export default ConfirmModal;
