import * as Sentry from '@sentry/browser';
import {
  EApiRoutes,
  EQueryKeys,
  ERestMethods,
  IRestEndpoints,
  EErrorTypes,
  IRequest,
  IBasicData,
  EApiErrors
} from 'interfaces';
import { API_URL } from 'data/config';

export const getHeaders = (token?: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }
  return headers;
};


export const handleError = async (
  err: any,
  errorType = EErrorTypes.Global,
) => {
  Sentry.captureException(err);
  const error = {
    status: err.status,
    message: err.message || err.statusText,
    type: errorType
  };
  return Promise.reject(error);
};

const parseResponse = async (response) => {
  const contentType = response.headers.get('Content-Type');
  if (!contentType) {
    return {};
  }
  if (contentType.includes('text/html')) {
    const text = await response.text();
    if (text === EApiErrors.CaldavUnauthorized) {
      return handleError(
        new Error('Invalid Username/Password'),
        EErrorTypes.Caldav
      );
    }
    return text;
  } else if (contentType.includes('json')) {
    const data = await response.json();
    return data;
  } else {
    const data = await response.json();
    return data;
  }
};

const replaceParams = (route, user) => {
  return route.includes(':customer_id') ?
    route.replace(':customer_id', user.customerId)
    : route;
};

export const sendRequest = async ({
  route,
  token = null,
  method = ERestMethods.GET,
  body = null,
  url = API_URL
}: IRequest) => {
  const request: any = {
    method,
    headers: getHeaders(token),
  };
  if (body) request.body = JSON.stringify(body);

  try {
    const response = await fetch(url + route, request);
    if (response.status >= 200 && response.status <= 299) {
      const result = await parseResponse(response);
      return result;
    } else {
      throw new Error(`Error calling ${url + route}`);
    }
  } catch (err) {
    return handleError(err, request);
  }
};

export const createDefaultRequests = <DataShape extends IBasicData>(
  queryKey: EQueryKeys,
  route: EApiRoutes,
  detailRoute?: EApiRoutes
): IRestEndpoints => {
  const get = (authUser) => async () => {

    const response: Array<DataShape> = await sendRequest({
      route: replaceParams(route, authUser),
      token: authUser.token,
      method: ERestMethods.GET
    });
    return response;
  };

  const create = (authUser) => async (data: DataShape) => {
    const response: DataShape = await sendRequest({
      route: replaceParams(route, authUser),
      token: authUser.token,
      method: ERestMethods.POST,
      body: data
    });
    return response;
  };

  const update = (authUser) => async (data: DataShape) => {
    const response: DataShape = await sendRequest({
      route: `${detailRoute || replaceParams(route, authUser)}/${data.id}`,
      token: authUser.token,
      method: ERestMethods.PATCH,
      body: data
    });
    return response;
  };

  const deleteData = (authUser) => async (data: DataShape) => {
    const response: any = await sendRequest({
      route: `${detailRoute || replaceParams(route, authUser)}/${data.id}`,
      token: authUser.token,
      method: ERestMethods.DELETE,
    });
    return response;
  };

  return {
    get,
    create,
    update,
    delete: deleteData
  };
};
