import styled from 'styled-components/macro';
import { Dropdown } from 'react-bootstrap';

export const MessageWrapper = styled.div`
  display: flex;
  margin-bottom: 19px;

  .message-detail {
    &__avatar {
      margin-right: 15px;
      width: 46px;
      min-width: 46px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 12px;
      overflow: hidden;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 13px 15px 10px 16px;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
      border-radius: 9px;
    }
  }

  .dropdown {
    visibility: hidden;

    @media (max-width: 1200px) {
      visibility: visible;
    }
  }

  &:hover {
    .dropdown {
      visibility: visible;
    }
  }

  &.left {
    padding-right: 26px;

    .message-detail {
      &__text {
        border-bottom-left-radius: 0;
      }
    }
  }

  &.right {
    padding-left: 22px;
    justify-content: flex-end;

    .message-detail {
      &__text {
        background: #9646C3;
        color: #FFFFFF;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  user-select: none;

  &.show {
    .dropdown-button {
      opacity: 0.8;
    }
  }

  .dropdown {
    &-button {
      width: 20px;
      height: 20px;
      padding: 1px 7px;
      cursor: pointer;
      border-radius: 50%;

      svg {
        height: 16px;

        path {
          fill: #04B3C0;
        }
      }
    }
    &-menu {
      min-width: 88px;
      box-shadow: 0px 7.91304px 22.6087px rgba(0, 0, 0, 0.15);
      border-radius: 5.65217px;
      padding: 5px 0;
    }
    &-item {
      font-weight: 400;
      font-size: 13px;
      line-height: 1;
      color: #1D1D1D;
      padding: 0.25rem 0.75rem;
      user-select: none;

      &.active, &:active {
        color: #FFFFFF;
      }
      &:hover {
        background-color: #04B3C0;
        color: #FFFFFF;
      }
    }
  }
`;

export const FeedbackWrapper = styled.div`
  padding-top: 8px;

  .suggest-feedback {
    &__message {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      margin-bottom: 15px;
    }

    &__textarea {
      background-color: #F3F7FA;
      margin-bottom: 18px;

      &::placeholder {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #858585;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      border-top: 1px solid #DCE7EF;
    }
  }
`;
