import React from 'react';
import { Pagination as BPagination } from 'react-bootstrap';
import { range } from 'lodash';
import { PaginationWrapper } from './style';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  gotoPage,
  nextPage,
  previousPage,
  ...props
}) => {
  const firstCount = pageCount > 6 ? 2 : pageCount;
  const lastCount = pageCount > 10 ? pageCount : 0;

  if (pageCount <= 0) {
    return (<></>);
  }

  return (
    <PaginationWrapper {...props}>
      <BPagination>
        <BPagination.First
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
        <BPagination.Prev
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />

        {range(0, firstCount).map((i) => (
          <BPagination.Item
            key={i}
            onClick={() => gotoPage(i)}
            active={pageIndex === i}
          >
            {i + 1}
          </BPagination.Item>
        ))}

        {(pageIndex !== firstCount && pageCount > 6) && (
          <BPagination.Ellipsis />
        )}

        {(pageIndex >= firstCount && pageIndex < lastCount - 2) && (
          <BPagination.Item active>{pageIndex + 1}</BPagination.Item>
        )}

        {(pageIndex >= firstCount && pageIndex < lastCount - 3) && (
          <BPagination.Ellipsis />
        )}

        {lastCount > 0 && range(lastCount - 2, lastCount).map((i) => (
          <BPagination.Item
            key={i}
            onClick={() => gotoPage(i)}
            active={pageIndex === i}
          >
            {i + 1}
          </BPagination.Item>
        ))}

        <BPagination.Next
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
        <BPagination.Last
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </BPagination>
    </PaginationWrapper>
  );
};

export default Pagination;
