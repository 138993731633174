import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Navbar as BNavbar } from 'react-bootstrap';

import Search from './search';
import Nav from './nav';
import Toggle from './toggle';
import CallNotification from './callNotification';

type Props = {
  stickyTop?: boolean,
  noSearchbar: boolean,
  title: string | React.ReactNode,
}
const Navbar: React.FC<Props> = ({ stickyTop, noSearchbar, title }) => {
  const [shouldShowCallNotification, showCallNotification] = useState(false);
  const [isInCall, setIsInCall] = useState(false);

  const handleAnswerCall = () => {
    setIsInCall(true);
  };

  return (
    <div className={classnames(
      'main-navbar',
      'bg-white',
      stickyTop && 'sticky-top'
    )}>
      <BNavbar className="align-items-stretch flex-md-nowrap p-0">
        <Toggle title={title} />
        {!noSearchbar && <Search />}
        <Nav />
        {shouldShowCallNotification && (
          <CallNotification
            isInCall={isInCall}
            handleAnswer={handleAnswerCall}
            handleDismiss={() => showCallNotification(false)}
          />
        )}
      </BNavbar>
    </div>
  );
};

Navbar.propTypes = {
  stickyTop: PropTypes.bool,
  noSearchbar: PropTypes.bool,
  title: PropTypes.string
};

Navbar.defaultProps = {
  stickyTop: true,
  noSearchbar: false,
  title: ''
};

export default Navbar;
