import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ToggleWrapper, DropdownWrapper } from './style';

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  <ToggleWrapper
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ToggleWrapper>
));

const Menu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    );
  },
);

const BaseDropdown = ({ label, children, ...props }) => {
  return (
    <DropdownWrapper {...props}>
      <Dropdown>
        <Dropdown.Toggle as={Toggle}>{label}</Dropdown.Toggle>

        <Dropdown.Menu as={Menu}>
          {children}
        </Dropdown.Menu>
      </Dropdown>
    </DropdownWrapper>
  );
};

export default Object.assign(BaseDropdown, Dropdown);
