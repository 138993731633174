import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import SoftphoneIcon from 'assets/image/icons/app/softphone.svg';
import AnswerCallIcon from 'assets/image/icons/app/answer-call.svg';
import DismissCallIcon from 'assets/image/icons/app/dismiss-call.svg';
import MuteCallIcon from 'assets/image/icons/app/mute-call.svg';
import ContactIcon from 'assets/image/icons/app/contact.svg';
import ProgressCallIcon from 'assets/image/icons/app/progress-call.svg';
import BackspaceIcon from 'assets/image/icons/app/backspace.svg';

type Props = {
  isInCall?: boolean,
}
const Softphone: React.FC<Props> = ({ isInCall }) => {
  const [number, setNumber] = useState('');

  const addNumber = (e: React.MouseEvent, num: string): void => {
    e.stopPropagation();

    setNumber((prevState: string): string => `${prevState}${num}`);
  };

  const backNumber = (e: React.MouseEvent): void => {
    e.stopPropagation();

    setNumber((prevState: string): string => `${prevState.slice(0, -1)}`);
  };

  return (
    <NavDropdown
      className="navbar-softphone"
      title={(
        <div className="navbar-softphone__toggler">
          <SoftphoneIcon />
        </div>
      )}
    >
      <NavDropdown.Item className="navbar-softphone__content">
        {isInCall ? (
          <div className="navbar-softphone__content-callpad">
            <div className="navbar-softphone__content-callpad-progress">
              <ProgressCallIcon />
            </div>
            <div className="navbar-softphone__content-callpad-number">
              +1 920 800 9291
            </div>
            <div className="navbar-softphone__content-callpad-time">
              01:29
            </div>
          </div>
        ) : (
          <div className="navbar-softphone__content-dialpad">
            <div className="navbar-softphone__content-dialpad-number">
              <div className="navbar-softphone__content-dialpad-numbers">
                {number}
              </div>
              <div
                className="navbar-softphone__content-dialpad-backspace"
                onClick={backNumber}
              >
                <BackspaceIcon />
              </div>
            </div>
            <div className="navbar-softphone__content-dialpad-pad">
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '1')}
              >
                1
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '2')}
              >
                2
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '3')}
              >
                3
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '4')}
              >
                4
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '5')}
              >
                5
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '6')}
              >
                6
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '7')}
              >
                7
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '8')}
              >
                8
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '9')}
              >
                9
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '*')}
              >
                *
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '0')}
              >
                0
              </div>
              <div
                className="navbar-softphone__content-dialpad-dial"
                onClick={(e) => addNumber(e, '#')}
              >
                #
              </div>
            </div>
          </div>
        )}
        <div className="navbar-softphone__content-footer">
          <div className="navbar-softphone__content-footer-left">
            {isInCall ? (
              <div className="navbar-softphone__content-action-wrapper gray">
                <MuteCallIcon className="navbar-softphone__content-mute" />
              </div>
            ) : (
              <div className="navbar-softphone__content-action-wrapper">
                <ContactIcon />
              </div>
            )}
          </div>
          <div className="navbar-softphone__content-footer-right">
            {isInCall ? (
              <div className="navbar-softphone__content-action-wrapper danger">
                <DismissCallIcon className="navbar-softphone__content-dismiss" />
              </div>
            ) : (
              <div className="navbar-softphone__content-action-wrapper success">
                <AnswerCallIcon className="navbar-softphone__content-answer" />
              </div>
            )}
          </div>
        </div>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default Softphone;
