import React from 'react';
import { navigate } from 'gatsby';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'components';
import { useRecoilValueLoadable } from 'recoil';
import { useSignOut } from 'data/auth';
import { AuthUserState } from 'data/auth';

const UserActions: React.VFC = () => {
  const authUserLoadable = useRecoilValueLoadable(AuthUserState);
  const user = authUserLoadable?.contents;
  const { logOut } = useSignOut(navigate);

  return (
    <NavDropdown
      className="navbar-user-actions"
      title={(
        <div className="navbar-user-actions__toggler">
          {user && (
            <>
              <img src={user.avatar} alt="Avatar" />
              <span>{user.fullName}</span>
            </>
          )}
        </div>
      )}
    >
      <NavDropdown.Item
        as={Link}
        to="/settings"
        className="navbar-user-actions__item"
      >
        My Account
      </NavDropdown.Item>
      <NavDropdown.Item
        as={Link}
        to="/billing"
        className="navbar-user-actions__item"
      >
        Subscription
      </NavDropdown.Item>
      <NavDropdown.Item
        as={Link}
        to="/settings"
        className="navbar-user-actions__item"
      >
        Settings
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={logOut}
        className="navbar-user-actions__item"
      >
        Sign Out
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserActions;
