import React, { useEffect } from 'react';

type WithChildren<T> =
  T & { children?: React.ReactNode };

type Props = WithChildren<{
  ref: React.MutableRefObject<HTMLElement>,
  callback: () => void,
}>

const useOutsideHandler = ({ ref, callback }: Props) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useOutsideHandler;
