import { useState, useEffect } from 'react';

const usePasswordValidation = ({
  password = '',
  passwordConfirmation = '',
  requiredLength = 8,
}) => {
  const [isMatched, setIsMatched] = useState<boolean>(false);
  const [isValidLength, setIsValidLength] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [isUpperCase, setIsUpperCase] = useState<boolean>(false);
  const [isLowerCase, setIsLowerCase] = useState<boolean>(false);
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false);

  useEffect(() => {
    setIsMatched(password && password === passwordConfirmation);
    setIsValidLength(password.length >= requiredLength);
    setIsUpperCase(password.toLowerCase() !== password);
    setIsLowerCase(password.toUpperCase() !== password);
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
  }, [password, passwordConfirmation, requiredLength]);

  return {
    isMatched,
    isValidLength,
    hasNumber,
    isUpperCase,
    isLowerCase,
    hasSpecialChar,
  };
};

export default usePasswordValidation;
