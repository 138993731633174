import React from 'react';
import { Navbar as BNavbar, NavbarBrand } from 'react-bootstrap';
import { BrandLogo } from 'components';
import Softphone from './softphone';

const Navbar: React.VFC = () => (
  <div className="main-navbar">
    <BNavbar className="align-items-center bg-white flex-md-nowrap p-0">
      <NavbarBrand
        className="w-100"
        href="/dashboard"
      >
        <BrandLogo className="main-logo" />
      </NavbarBrand>
      <Softphone />
    </BNavbar>
  </div>
);

export default Navbar;
