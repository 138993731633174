export interface IShape {
  id: number;
  [key: string]: any;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  notes?: string;
  phone?: string;
  website?: string;
  fax?: string;
};

export interface IForm {
  name: string;
  phone: string;
  addressLine1: string;
};

export const Validation = {
  name: {
    type: 'string',
    required: true,
  },
  addressLine1: {
    type: 'string',
    required: true,
  },
  phone: {
    type: 'string',
    required: true,
  },
  addressLine2: {
    type: 'string'
  },
  city: {
    type: 'string'
  },
  country: {
    type: 'string'
  },
  fax: {
    type: 'string'
  },
  notes: {
    type: 'string'
  },
  state: {
    type: 'string'
  },
  website: {
    type: 'string'
  },
  zip: {
    type: 'string'
  },
};
