import React from 'react';
import SearchIcon from 'assets/image/icons/app/search.svg';
import { SearchboxWrapper } from './style';

const Searchbox = ({className, ...rest}) => (
  <SearchboxWrapper className={className}>
    <SearchIcon />
    <input type="text" {...rest} />
  </SearchboxWrapper>
);

export default Searchbox;
