import React, { useContext } from 'react';
import classnames from 'classnames';

import GlobalAppContext from 'context/globalAppContext';
import { Plan } from 'components';
import { useIsMobile } from 'components/hooks';
import Navbar from './navbar';
import ProfileBar from './profileBar';
import NavItems from './navItems';

const Sidebar = () => {
  const { state: { sidebar, }}: any = useContext(GlobalAppContext);
  const isMobile = useIsMobile();

  const classes = classnames(
    'main-sidebar',
    'px-0',
    sidebar.open && 'open'
  );

  return (
    <aside className={classes}>
      <Navbar />
      {isMobile && <ProfileBar />}
      <NavItems />
      <Plan className="main-sidebar__plan" />
    </aside>
  );
};

export default Sidebar;
