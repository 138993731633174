import React from 'react';
import AvatarIcon from 'assets/image/pages/app/user-actions-avatar.png';

const ProfileBar: React.VFC = () => (
  <div className="main-sidebar__profile">
    <div className="main-sidebar__profile-img-wrapper">
      <img src={AvatarIcon} alt="Avatar" />
    </div>
    <div className="main-sidebar__profile-content">
      <h5>John Doe</h5>
      <p>john.doe@mail.com</p>
    </div>
  </div>
);

export default ProfileBar;
