import React from 'react';

import { App } from './src/components';
import { GlobalAppProvider } from './src/context/globalAppContext';

import './node_modules/slick-carousel/slick/slick.css';
import './src/assets/fonts/fontawesome-5/css/all.min.css';
import './src/styles/bootstrap.scss';
import './src/styles/global.scss';
import './node_modules/react-modal-video/scss/modal-video.scss';


export const wrapPageElement = ({ element, props }) => (
  <App {...props}>{element}</App>
);

export const wrapRootElement = ({ element }) => (
  <GlobalAppProvider>{element}</GlobalAppProvider>
);
