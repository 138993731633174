import styled from 'styled-components';

export const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const FullContainerLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #E1EBF0;
  opacity: .5;
  z-index: 50;
`;
