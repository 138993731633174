import styled from 'styled-components/macro';

const SiteHeader = styled.div.attrs({
  className: 'site-header',
})`
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #e1ebf0;

  @media (max-width: 992px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 575px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  .hamburger {
    order: 3;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .site-brand {
    order: 1;
  }
  .header-btns {
    order: 2;
  }
  .site-menu-main {
    width: 100%;
    .dropdown-menu {
      z-index: 500;
      background-color: #fff;
      padding: 20px 0px;
      transition: opacity 0.5s ease, top 0.5s ease, margin-top 0.5s ease,
        background-color 0.5s ease;
      margin-top: 25px;
      @media (min-width: 992px) {
        box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
        position: absolute;
        opacity: 0;
        visibility: hidden;
        min-width: 227px;
        max-width: 227px;
        top: 115%;
        margin-top: 0;
        visibility: visible;
        opacity: 1;
        z-index: 99;
        pointer-events: visible;
        border-radius: 0px 0px 15px 15px;
        border-top: 3px solid #fbb040;
        display: block !important;
        visibility: hidden;
        opacity: 0;
        z-index: -99;
        font-weight: 500;
        transition: top 0.4s linear;
      }
      @media (max-width: 992px) {
        width: 100%;
        border-color: transparent;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .main-nav-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      > .nav-link {
        position: relative;
        color: #fff;
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        padding: 17px 24px 15px 24px;
        transition: 0.4s;
        &:hover {
          color: #08b6c2;
        }
        @media (max-width: 992px) {
          width: 100%;
          border-bottom: 1px solid #ececec;
          padding: 7px 20px 5px;
        }
      }
      // &.show,{
      &:hover {
        & > .dropdown-menu {
          @media (min-width: 992px) {
            top: 98%;
            margin-top: 0;
            visibility: visible;
            opacity: 1;
            z-index: 99;
            pointer-events: visible;
            border-radius: 0px 0px 15px 15px;
            border-top: 3px solid #fbb040;
          }
        }
      }
    }
    .drop-menu-item {
      font-size: 16px;
      font-weight: 500;
      padding-top: 0px;
      padding-bottom: 0px;
      @media (min-width: 992px) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      > a,
      .dropdown-toggle {
        color: #000 !important;
        text-decoration: none;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: inherit;
        @media (min-width: 992px) {
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
        @media (max-width: 992px) {
          padding-left: 30px;
          padding-right: 20px;
          border-bottom: 1px solid #ececec;
        }
      }
    }
    .innerDropdown {
      & > .dropdown-menu {
        @media (min-width: 992px) {
          transform: translate(-95%);
          pointer-events: none;
        }
      }
      & > .dropdown-toggle {
        display: flex !important;
        justify-content: space-between;
        @media (min-width: 992px) {
          &:after {
            top: 0px;
            transform: rotate(-90deg);
            margin-left: 0px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
      //&.show, {
      &:hover {
        & > .dropdown-menu {
          @media (min-width: 992px) {
            top: 0%;
            margin-top: 0;
            visibility: visible;
            opacity: 1;
            z-index: 99;
            pointer-events: visible;
            border-radius: 0px 0px 15px 15px;
            border-top: 3px solid #fbb040;
            transition: top 0.4s linear;
          }
        }
        & > .dropdown-toggle {
          @media (min-width: 992px) {
            &:after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    .login-link {
      margin-left: auto;
      @media (max-width: 992px) {
        margin-left: 0px;
      }
    }
    .register-link {
      background-color: white;
      padding: 8px 24px 6px 23px !important;
      border-radius: 30px;
      line-height: 28px;
      font-weight: 600 !important;

      @media (max-width: 992px) {
        background: #9646C3;
        color: #fff !important;
        text-align: center;
      }
    }
    .register-link-wrapper {
      @media (max-width: 992px) {
        padding: 14px 20px;
      }
    }
  }
  .navbar-collapse {
    @media (max-width: 992px) {
      position: fixed;
      display: block !important;
      width: 100%;
      position: fixed;
      top: -150%;
      left: 0;
      background: #fff;
      transition: top 0.4s;
      z-index: 999999;
      box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
      overflow-y: auto;

      &.show {
        top: 65px;
      }
      &.collapsing {
        transition: height 0s;
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #416ff4;
        outline: 1px solid slategrey;
      }
      .offcanvas-close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: right;
        border-bottom: 1px solid #ececec;
        padding: 0;
        span {
          border-left: 1px solid #ececec;
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          padding: 20px;
          &:before {
            content: '\f00d';
            color: #000;
            font-family: 'Font Awesome 5 free';
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }
  }
  .dropdown-toggle {
    @media (max-width: 992px) {
      display: flex !important;
      justify-content: space-between;
      min-width: 50px;
      display: block;
      width: 100%;
    }
    &[aria-expanded='true']:after {
      @media (max-width: 992px) {
        content: '\f068';
      }
    }
    &:after {
      border: none;
      content: '\f078';
      font-family: 'Font Awesome 5 free';
      font-weight: 700;
      font-size: 11px;
      position: relative;
      top: 1px;
      margin-left: 9px;
      transition: 0.4s;
      transform-origin: center;
      transform-box: view-box;
      @media (max-width: 992px) {
        margin-right: 9px;
        content: '\f078';
      }
    }
  }
  .main-nav-item.show {
    > .dropdown-toggle:after {
      @media (min-width: 992px) {
        transform: rotate(-180deg);
      }
    }
  }
  .site-navbar {
    @media (min-width: 992px) {
      position: static;
    }
  }
  .container-fluid {
    .site-navbar {
      @media (min-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (min-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .show {
    & > .dropdown-toggle {
      &:after {
        @media (max-width: 992px) {
          content: '\f077';
        }
      }
    }
    & > .dropdown-menu {
      @media (max-width: 992px) {
        display: block;
        margin-top: 0 !important;
      }
    }
  }

  &.site-header--sticky {
    &:not(.mobile-sticky-enable) {
      position: absolute !important;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;

      @media (min-width: 992px) {
        position: fixed !important;
        transition: 0.4s;

        &.scrolling {
          transform: translateY(-100%);
          transition: 0.4s;
          .site-navbar {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        &.reveal-header {
          transform: translateY(0%);
          box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
          z-index: 1000;
        }
      }
      &.reveal-header {
        @media (min-width: 992px) {
          padding-top: 10px;
          z-index: 999;
          padding-bottom: 10px;
        }
      }
    }

    &.mobile-sticky-enable {
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      position: fixed !important;
      transition: 0.4s;
      &.scrolling {
        transform: translateY(-100%);
        transition: 0.4s;
      }

      &.reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
      }
    }
  }
  /* Light Header */
  &.light-header {
    .main-nav-item > .nav-link {
      color: #1D1D1D;
      &:hover {
        color: #08b6c2;
      }
    }
    &.reveal-header {
      background: #fff;
    }
  }
  /* Dark Header */
  &.dark-header {
    &.reveal-header {
      background: #19191b;
    }
    &-2 {
      &.reveal-header {
        background: #416ff4;
      }
    }
  }

  &.site-header--menu-center {
    .navbar-collapse {
      @media (min-width: 992px) {
        justify-content: center;
        margin-right: calc(-214px / 2);
      }
    }
  }
  &.site-header--menu-end {
    .navbar-collapse {
      justify-content: flex-end;
    }
  }
  &.site-header--menu-start {
    .container-fluid {
      .navbar-collapse {
        @media (min-width: 992px) {
          padding-left: 15px;
          margin-left: 3.125rem !important;
        }
      }
    }
  }

  /* Header Buttons */
  &.site-header--menu-sep {
    @media (min-width: 992px) {
      .navbar-collapse {
        margin-right: 20px;
        padding-right: 10px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 26px;
          border-right: 2px solid rgba(130, 130, 136, 0.3);
        }
      }
    }
  }
  &.site-header--button-sep {
    .header-btn-block {
      display: none;
      @media (min-width: 480px) {
        display: block;
        margin-left: auto;
        margin-right: 20px;
      }
      @media (min-width: 992px) {
        margin-right: 0px;
        margin-left: 20px;
        &:before {
          content: '';
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 26px;
          border-right: 2px solid rgba(130, 130, 136, 0.3);
        }
      }
    }
  }

  &.site-header--with-border {
    .navbar-brand {
      @media (min-width: 992px) {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: -21px;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 26px;
          border-left: 2px solid rgba(38, 39, 41, 0.3);
          opacity: 0;
        }
      }
    }
  }
  &.site-header--services {
    .navbar-collapse {
      @media (min-width: 992px) {
        margin-right: 55px;
      }
    }
  }

  &.no-header {
    display: none;
    height: 0;
  }

  .navbar-brand img {
    max-width: 160px;

    @media (max-width: 575px) {
      max-width: 140px;
    }
  }
`;

export default SiteHeader;
