import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const ModalWrapper = styled(Modal)`
  &.left .modal-dialog,
  &.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 335px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);

    @media (max-width: 575px) {
      width: 100%;
      height: auto;
      max-height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  &.left .modal-content,
  &.right .modal-content {
    height: 100%;
    overflow-y: auto;

    @media (max-width: 575px) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  &.left.fade {
    .modal-dialog {
      left: -335px;
      -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
         -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
           -o-transition: opacity 0.3s linear, left 0.3s ease-out;
              transition: opacity 0.3s linear, left 0.3s ease-out;
    }
    &.show .modal-dialog{
      left: 0;
    }
  }

  &.right.fade {
    .modal-dialog {
      right: -335px;
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
         -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
           -o-transition: opacity 0.3s linear, right 0.3s ease-out;
              transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    &.show .modal-dialog {
      right: 0;
    }
  }

  .modal {
    &-content {
      border: none;
      border-radius: 0;
    }

    &-header {
      background-color: #F3F7FA;
      padding: 23px 28px 16px 28px;
      border-bottom: 1px dashed #D1DADA;

      @media (max-width: 575px) {
        justify-content: center;
        padding: 17px 17px 0;
        border: none;
      }

      .btn-close {
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;

        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    &-title {
      font-size: 16px;
      line-height: 19px;
      color: #1D1D1D;

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-body {
      padding: 0;
    }

    &-footer {
      flex-direction: column;
      padding: 30px 32px 12px 32px;
      border-top: none;

      @media (max-width: 575px) {
        border: none;
        padding-bottom: 0;
      }

      .btn {
        width: 100%;
        height: 40px;
        margin-bottom: 12px;

        @media (max-width: 575px) {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          height: 50px;
        }
      }

      .btn-default {
        border: 1px solid #D5D5D5;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

        @media (max-width: 575px) {
          border: none;
          box-shadow: none;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          width: auto;
          height: 22px;
        }
      }
    }
  }
`;
