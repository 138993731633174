import React from 'react';
import { TitleWrapper } from './style';

type Props = {
  title: string,
  description?: string,
  handleBack?: () => void,
  children?: any,
}
const Title: React.FC<Props> = ({
  title, description, handleBack, children, ...props
}) => (
  <TitleWrapper {...props}>
    <div className="page-title__wrapper">
      {handleBack ? (
        <>
          <span className="page-title__back" onClick={handleBack}>
            <i className="fa fa-long-arrow-alt-left" />&nbsp;&nbsp;Back
          </span>
          <h1 className="page-title__subtitle">{title}</h1>
        </>
      ) : (
        <h1 className="page-title__title">{title}</h1>
      )}
      {description && <p className="page-title__description">{description}</p>}
    </div>
    <div className="page-title__action">
      {children}
    </div>
  </TitleWrapper>
);

export default Title;
