import React from 'react';
import { Link } from 'gatsby';
import { NavDropdown } from 'react-bootstrap';
import NotificationIcon from 'assets/image/icons/app/notification.svg';
import CallIcon from 'assets/image/icons/app/notification-call.svg';
import PlanIcon from 'assets/image/icons/app/notification-plan.svg';
import MailIcon from 'assets/image/icons/app/notification-mail.svg';
import AvatarIcon from 'assets/image/pages/app/notification-avatar.png';

const Notifications: React.VFC = () => (
  <NavDropdown
    className="navbar-notifications"
    title={(
      <div className="navbar-notifications__toggler">
        <NotificationIcon />
        <div className="navbar-notifications__toggler-badge" />
      </div>
    )}
  >
    <NavDropdown.Item className="navbar-notifications__item">
      <div className="navbar-notifications__item-icon navbar-notifications__item-icon-call">
        <CallIcon />
      </div>
      <div className="navbar-notifications__item-content">
        <span className="navbar-notifications__item-content-category">Andrew Smith</span>
        <p className="navbar-notifications__item-content-description">
          +1 920.3930.489
        </p>
      </div>
      <div className="navbar-notifications__item-avatar">
        <img src={AvatarIcon} alt="Avatar" />
      </div>
    </NavDropdown.Item>
    <NavDropdown.Item className="navbar-notifications__item">
      <div className="navbar-notifications__item-icon navbar-notifications__item-icon-plan">
        <PlanIcon />
      </div>
      <div className="navbar-notifications__item-content">
        <span className="navbar-notifications__item-content-category">Upgrade now!</span>
        <p className="navbar-notifications__item-content-description">
          Your FREE plan is over.
        </p>
      </div>
    </NavDropdown.Item>
    <NavDropdown.Item className="navbar-notifications__item">
      <div className="navbar-notifications__item-icon navbar-notifications__item-icon-mail">
        <MailIcon />
      </div>
      <div className="navbar-notifications__item-content">
        <span className="navbar-notifications__item-content-category">John Doe</span>
        <p className="navbar-notifications__item-content-description">
          +1 920.3930.489
        </p>
      </div>
      <div className="navbar-notifications__item-avatar">
        <img src={AvatarIcon} alt="Avatar" />
      </div>
    </NavDropdown.Item>
    <NavDropdown.Item
      as={Link}
      className="navbar-notifications__item-all"
      to="/notifications"
    >
      See all notifications
    </NavDropdown.Item>
  </NavDropdown>
);

export default Notifications;
