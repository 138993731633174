import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import Header from '../header';
import favicon from 'assets/image/favicon.png';

type TProps = {
  children: any
}
const App: React.FC<TProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Voicio</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Header />
      {children}
      <ToastContainer />
    </>
  );
};

export default App;
