import React, { useEffect, useRef, forwardRef } from 'react';
import { CheckboxWrapper } from './style';

const Checkbox = forwardRef(({
  indeterminate, id, name, checked, onChange, ...rest
}: any, ref: any) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <CheckboxWrapper {...rest}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        ref={resolvedRef}
      />
      <span className="checkmark"></span>
    </CheckboxWrapper>
  );
});

export default Checkbox;
