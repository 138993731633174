import React from 'react';
import MoreIcon from 'assets/image/icons/app/more.svg';

type CustomToggleProps = {
  children: any,
  onClick: React.MouseEventHandler<HTMLDivElement>
}
const CustomToggle =
  React.forwardRef<HTMLDivElement, CustomToggleProps>(({
    children, onClick
  }, ref) => (
    <div
      className="dropdown-button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <MoreIcon />
    </div>
  ));

export default CustomToggle;
