import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalWrapper } from './style';

type Props = {
  className?: string,
  show: boolean,
  onHide: () => void,
  title?: any,
  children?: any,
  footer?: any,
  backdrop?: string,
}
const CustomModal: React.FC<Props> = ({
  title, children, footer = null, ...props
}) => (
  <ModalWrapper {...props}>
    {title && (
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>
      {children}
    </Modal.Body>
    {footer && (
      <Modal.Footer>
        {footer}
      </Modal.Footer>
    )}
  </ModalWrapper>
);

export default CustomModal;
