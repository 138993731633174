import { ELoadableScripts } from 'interfaces';

const ScriptMap: Record<ELoadableScripts, string> = {
  [ELoadableScripts.GoogleMaps]:
    `https://maps.googleapis.com/maps/api/js?v=3.26&key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`
};

const loadError = (oError) => {
  throw new URIError('The script ' + oError.target.src + ' didn\'t load correctly.');
};
const affixScriptToHead =
  (scriptKey: ELoadableScripts, onloadFunction = null) => {
    const scriptExists = document.getElementById(scriptKey); 
    if (!scriptExists) {
      const scriptUrl = ScriptMap[scriptKey];
      const newScript = document.createElement('script');
      newScript.id = scriptKey;
      newScript.onerror = loadError;
      if (onloadFunction) { newScript.onload = onloadFunction; }
      document.head.appendChild(newScript);
      newScript.src = scriptUrl;
    } else {
      onloadFunction();
    }
  };

export const loadScript =
  (scriptKey: ELoadableScripts, callback = null) => {
    affixScriptToHead(scriptKey, callback);
  };
