import React from 'react';
import classnames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { CustomToggle, Modal, Form, Button } from 'components';
import { useModal } from 'components/hooks';
import { MessageWrapper, DropdownWrapper, FeedbackWrapper } from './style';

type TProps = {
  Avatar: null | string,
  left: boolean,
  message: string,
  isFeedbackSuggestable: boolean,
}
const Message: React.FC<TProps> = ({
  Avatar, left, message, isFeedbackSuggestable, ...props
}) => {
  const {isModalOpen, showModal, hideModal} = useModal();

  return (
    <MessageWrapper
      className={classnames({
        left,
        right: !left,
      })}
      {...props}
    >
      {Avatar && <div className="message-detail__avatar"><Avatar /></div>}
      <div className="message-detail__text">{message}</div>
      {isFeedbackSuggestable && (
        <DropdownWrapper>
          <Dropdown.Toggle as={CustomToggle}>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={showModal}>
              Suggest Feedback
            </Dropdown.Item>
          </Dropdown.Menu>
        </DropdownWrapper>
      )}

      <Modal
        className="modal-mini"
        show={isModalOpen}
        onHide={hideModal}
        title="Suggest Feedback"
      >
        <FeedbackWrapper>
          <Form>
            <div className="suggest-feedback__message">{message}</div>
            <Form.Textarea
              placeholder="Provide some feedback on how we can improve this message."
              className="suggest-feedback__textarea"
            />
            <div className="suggest-feedback__footer">
              <Button variant="primary" type="button" round>
                Submit
              </Button>
            </div>
          </Form>
        </FeedbackWrapper>
      </Modal>
    </MessageWrapper>
  );
};

export default Message;
