import styled from 'styled-components/macro';

export const PaginationWrapper = styled.div.attrs(props => ({
  className: `pagination-wrapper ${props.className || ''}`,
}))`
  .pagination {
    margin-bottom: 0;

    .page-item {
      .page-link {
        border: none;
        border-radius: 6px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        padding: 4px 9px;
        color: #8A9DA4;
      }

      &.active {
        .page-link {
          color: #FFFFFF;
        }
      }

      &.disabled {
        .page-link {
          color: #C6D9E0;
        }
      }
    }
  }
`;
