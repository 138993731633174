import styled from 'styled-components/macro';

export const TableWrapper = styled.div.attrs(props => ({
  className: `main-table ${props.className || ''}`,
}))`
  .main-table {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #1D1D1D;
        margin-bottom: 0;
      }

      &-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-select {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #4B595E;
          padding: 7px 14px 6px;
        }

        &-checkbox {
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #4B595E;

          > label {
            margin-right: 4px;
          }
        }

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F3F7FA;
          width: 30px;
          height: 30px;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      @media (max-width: 1200px) {
        &.custom {
          flex-direction: column;
          align-items: flex-start;

          h4 {
            margin-bottom: 5px;
          }

          .main-table__header-filter {
            margin-bottom: 5px;
          }
        }
      }
    }

    &__content {
      border-spacing: 0;
      border: none;
      width: 100%;
      margin-top: 5px;

      thead {
        th {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
          color: #8A9DA4;
          padding: 11px;
          margin: 0;
          border: none;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #F3F7FA;
            border-radius: 8px;
          }
          &.selected {
            background-color: #E8F2F9;
          }
        }
        td {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;
          padding: 14px 11px;
          margin: 0;
          border: none;

          img {
            width: 25px;
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding-top: 10px;
      border-top: 1px solid #DCE7EF;

      &-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F3F7FA;
          width: 30px;
          height: 30px;
          border-radius: 10px;
          margin-right: 11px;
        }
      }

      &-info {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #8A9DA4;
        margin-bottom: 0;
      }
    }
  }
`;
