import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import GlobalAppContext from 'context/globalAppContext';
import SiteNav from './siteNav';
import SiteHeader from './style';

const Header: React.VFC = () => {
  const [isShowScrolling, setShowScrolling] = useState(false);
  const [isShowReveal, setShowReveal] = useState(false);
  const [hasMounted, setHasMounted] = React.useState(false);
  const { state: { header, }} = useContext(GlobalAppContext);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <SiteHeader
      className={classnames(`${header.headerClasses} site-header--sticky`, {
        scrolling: isShowScrolling,
        'reveal-header': isShowReveal,
      })}
    >
      <SiteNav
        defaultLogo={header.defaultLogo}
        customLogo={header.customLogo}
      />
    </SiteHeader>
  );
};

export default Header;
