import React from 'react';
import Logo from 'assets/image/logo/logo.png';

type Props = {
  className?: string
}

const BrandLogo: React.FC<Props> = (props) => (
  <div className={props.className}>
    <img src={Logo} alt="Voicio" />
  </div>
);

export default BrandLogo;
