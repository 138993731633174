import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export type TRecorder = {
  recordingMinutes: number;
  recordingSeconds: number;
  isRecording: boolean;
  isActiveRecording: boolean;
  mediaStream: MediaStream | null;
  mediaRecorder: MediaRecorder | null;
  audio: string | null;
};

export type TUseRecorder = {
  recorderState: TRecorder;
  startRecording: () => void;
  cancelRecording: () => void;
  saveRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
};

export type TRecorderControlsProps = {
  recorderState: TRecorder;
  handlers: {
    startRecording: () => void;
    cancelRecording: () => void;
    saveRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
  };
};

export type TRecordingsListProps = {
  audio: string | null;
};

export type Audio = {
  key: string;
  audio: string;
};

export type TSetRecordings = Dispatch<SetStateAction<Audio[]>>;
export type TSetRecorder = Dispatch<SetStateAction<TRecorder>>;
export type TInterval = null | number | ReturnType<typeof setInterval>;
export type TAudioTrack = MediaStreamTrack;
export type TMediaRecorderEvent = {
  data: Blob;
};

async function startRecording(setRecorderState: TSetRecorder): Promise<any> {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true
    });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        isRecording: true,
        isActiveRecording: true,
        mediaStream: stream,
      };
    });
  } catch (err) {
    console.log(err);
  }
}

export function saveRecording(recorder: any): void {
  if (recorder.state !== 'inactive') {
    recorder.stop();
  }
}

export function pauseRecording(
  recorder: any, setRecorderState: TSetRecorder
): void {
  if (recorder.state === 'recording') {
    setRecorderState((prevState) => {
      return {
        ...prevState,
        isActiveRecording: false,
      };
    });

    recorder.pause();
  }
}

export function resumeRecording(
  recorder: any, setRecorderState: TSetRecorder
): void {
  if (recorder.state === 'paused') {
    setRecorderState((prevState) => {
      return {
        ...prevState,
        isActiveRecording: true,
      };
    });

    recorder.resume();
  }
}

const initialState: TRecorder = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  isRecording: false,
  isActiveRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
};

export default function useRecorder(): TUseRecorder {
  const [recorderState, setRecorderState] = useState<TRecorder>(initialState);

  useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval: TInterval = null;

    if (recorderState.isRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState((prevState: TRecorder) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            typeof recordingInterval === 'number' && clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.isActiveRecording &&
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          ) {
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
          } else if (
            prevState.isActiveRecording &&
            prevState.recordingSeconds === 59
          ) {
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
          } else {
            return prevState;
          }
        });
      }, 1000);
    } else {
      typeof recordingInterval === 'number' && clearInterval(recordingInterval);
    }

    return () => {
      typeof recordingInterval === 'number' && clearInterval(recordingInterval);
    };
  }, [recorderState.isRecording]);

  useEffect(() => {
    setRecorderState((prevState) => {
      if (prevState.mediaStream) {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      } else {
        return prevState;
      }
    });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks: Blob[] = [];

    if (recorder && recorder.state === 'inactive') {
      recorder.start();

      recorder.ondataavailable = (e: TMediaRecorderEvent) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        chunks = [];

        setRecorderState((prevState: TRecorder) => {
          if (prevState.mediaRecorder) {
            return {
              ...initialState,
              audio: window.URL.createObjectURL(blob),
            };
          }

          else {
            return initialState;
          }
        });
      };
    }

    return () => {
      if (recorder) {
        recorder.stream.getAudioTracks().forEach(
          (track: TAudioTrack) => track.stop()
        );
      }
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
    pauseRecording: () =>
      pauseRecording(recorderState.mediaRecorder, setRecorderState),
    resumeRecording: () =>
      resumeRecording(recorderState.mediaRecorder, setRecorderState),
  };
}
