import styled from 'styled-components/macro';

export const PlayerWrapper = styled.div`
  text-align: center;
  user-select: none;

  .audio-track {
    &__progress {
      height: 5px;
      -webkit-appearance: none;
      width: 100%;
      margin-bottom: 4px;
      border-radius: 8px;
      background: #9646C3;
      transition: background 0.2s ease;
      cursor: pointer;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 5px;
        width: 30px;
        border: none;
        border-radius: 0;
        background: #FFB600;
        cursor: pointer;
        box-shadow: none;
      }
      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 5px;
        width: 30px;
        border: none;
        border-radius: 0;
        background: #FFB600;
        cursor: pointer;
        box-shadow: none;
      }
      &::-ms-thumb {
        -webkit-appearance: none;
        height: 5px;
        width: 30px;
        border: none;
        border-radius: 0;
        background: #FFB600;
        cursor: pointer;
        box-shadow: none;
      }
    }

    &__times {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;

      @media (max-width: 575px) {
        font-size: 13px;
        line-height: 15px;
      }

      &-start {
        color: #1D1D1D;
      }
      &-end {
        color: #9646C3;
      }
      &-floating {
        position: absolute;
        top: -28px;
        color: #FFB600;
        margin-left: -12px;
      }
    }
  }
`;

export const ControlWrapper = styled.div`
  text-align: left;
  margin-top: 26px;

  .audio-control {
    &__play, &__pause {
      cursor: pointer;
      transition: all 0.35s;

      &:hover {
        opacity: 0.8.
      }

      @media (max-width: 575px) {
        width: 56px;
        height: 56px;
      }
    }

    &__pause {
      cursor: pointer;
    }
  }
`;