import React from 'react';
import { Nav } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from '@reach/router';

import NavItem from './navItem';
import { menuItems } from 'data/sideMenus';

const NavItems: React.VFC = () => {
  const location = useLocation();

  const mainItems = menuItems.filter(item => item.category === 'menu');
  const additionalItems = menuItems.filter(item => item.category === 'additional');
  const mobileItems = menuItems.filter(item => item.category === 'mobile');

  const activePath = location.pathname.replace(/\/$/, '');

  return (
    <div className="nav-wrapper">
      <Scrollbars universal autoHide>
        <Nav className="flex-column" activeKey={activePath}>
          <label className="nav-label d-none d-sm-flex d-md-flex d-lg-flex">
            MENU
          </label>
          {mainItems.map((item, idx) => (
            <NavItem
              key={idx}
              item={item}
              className="d-none d-sm-flex d-md-flex d-lg-flex"
            />
          ))}

          <label className="nav-label d-none d-sm-flex d-md-flex d-lg-flex">
            ADDITIONAL
          </label>
          {additionalItems.map((item, idx) => (
            <NavItem key={idx} item={item} />
          ))}

          {mobileItems.map((item, idx) => (
            <NavItem
              key={idx}
              item={item}
              className="d-flex d-sm-none d-md-none d-lg-none"
            />
          ))}
        </Nav>
      </Scrollbars>
    </div>
  );
};

export default NavItems;
