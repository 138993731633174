import { v4 as uuidv4 } from 'uuid';

export const createOptions =
  ({ queryClient, queryKey, logger, handleError }) =>
    ({
      onMutate: async (data) => {
        const tempID = uuidv4();
        const newData = { ...data, tempID };
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey,
          (oldData: Array<any>) => [newData, ...oldData]);
        logger('Create/Mutate', { data });
        return { tempID };
      },
      onSuccess: async (response, data, context: any) => {
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey,
          (oldData: Array<any>) => oldData.map(datum =>
            datum.tempID === context.tempID
              ? response : datum
          ));
        logger('Create/Success', { response, data, context });
      },
      onError: (error: any, data, context) => {
        logger('Create/Error', { data, context });
        queryClient.setQueryData(queryKey,
          (oldData: Array<any>) => oldData.filter(datum =>
            !(datum.tempID === context.tempID)
          )
        );
        handleError(error);
      }
    });

export const updateOptions =
  ({ queryClient, queryKey, logger, handleError }) =>
    ({
      onMutate: async (newData) => {
        await queryClient.cancelQueries(queryKey);
        const oldData = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey,
          (oldData: Array<any>) => oldData.map(datum =>
            datum.id === newData.id
              ? newData : datum
          ));
        logger('Create/Mutate', { data: newData });
        return { oldData };
      },
      onSuccess: async (response, data, context: any) => {
        logger('Create/Success', { response, data, context });
      },
      onError: (error: any, data, context) => {
        logger('Create/Error', { data, context });
        queryClient.setQueryData(queryKey,
          () => context.oldData
        );
        handleError(error);
      }
    });


