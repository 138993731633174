import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { Container, Row } from 'react-bootstrap';

import GlobalAppContext from 'context/globalAppContext';
import { SET_HEADER } from 'context/actions';
import Navbar from '../layout/navbar';
import Sidebar from '../layout/sidebar';
import { SoftphonePanel } from '../layout/sidebar/softphone';
import Bottombar from '../layout/bottombar';
import { useIsMobile } from 'components/hooks';

const headerConfig = {
  headerClasses: 'no-header',
};

const AppPageWrapper = ({
  children,
  noNavbar = false,
  noSearchbar = false,
  title,
}) => {
  const {
    state: { sidebar, softphone, },
    dispatch,
  }: any = useContext(GlobalAppContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch({
      type: SET_HEADER,
      payload: { ...headerConfig }
    });
  }, [dispatch]);

  const classes = classnames(
    'main-content',
    'p-0',
    sidebar.open && 'open'
  );

  return (
    <Container fluid>
      <Row>
        <Sidebar />
        <main className={classes}>
          {!noNavbar && <Navbar title={title} noSearchbar={noSearchbar} />}
          {children}
          {isMobile && <Bottombar />}
        </main>
        {softphone.open && isMobile && <SoftphonePanel />}
      </Row>
    </Container>
  );
};

export default AppPageWrapper;
