import Logo from 'assets/image/logo/logo.png';

const defaultState = {
  header: {
    headerClasses:
      'light-header light-header site-header--menu-start position-relative site-header--sticky',
    containerFluid: true,
    defaultLogo: Logo,
  },
  sidebar: {
    open: false,
  },
  softphone: {
    open: false,
  },
};

export default defaultState;
