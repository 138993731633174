import { useContext, useEffect, useCallback } from 'react';

import GlobalAppContext from 'context/globalAppContext';
import { SET_HEADER } from 'context/actions';

const PageWrapper = ({ children, headerConfig = null }) => {
  const { dispatch } = useContext(GlobalAppContext);

  const setHeader = useCallback(() => {
    dispatch({
      type: SET_HEADER,
      payload: { ...headerConfig }
    });
  }, [dispatch, headerConfig]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  return children;
};

export default PageWrapper;
