import React from 'react';
import classnames from 'classnames';
import { useIsMobile } from 'components/hooks';
import AnswerCallIcon from 'assets/image/icons/app/answer-call.svg';
import DismissCallIcon from 'assets/image/icons/app/dismiss-call.svg';
import MuteCallIcon from 'assets/image/icons/app/mute-call.svg';
import ProgressCallIcon from 'assets/image/icons/app/progress-call.svg';
import CallsIcon from 'assets/image/icons/sidebar/calls.svg';

type Props = {
  isInCall?: boolean,
  handleAnswer: () => void,
  handleDismiss: () => void,
}
const CallNotification: React.FC<Props> = ({
  isInCall, handleAnswer, handleDismiss,
}) => {
  const isMobile = useIsMobile();

  return (
    <div className="call-notification">
      {isInCall ? (
        <div className="call-notification__callpad">
          <div className="call-notification__callpad-progress">
            <ProgressCallIcon className="call-notification__calling-icon-progress" />
          </div>
          <div className="call-notification__callpad-content">
            <div className="call-notification__callpad-text">
              Calling...
            </div>
            <div className="call-notification__callpad-number">
              (354) 520-8731
            </div>
          </div>
        </div>
      ) : (
        <div className="call-notification__calling">
          {isMobile ? (
            <div className="call-notification__calling-progress">
              <ProgressCallIcon className="call-notification__calling-icon-progress" />
            </div>
          ) : (
            <CallsIcon className="call-notification__calling-icon"/>
          )}
          <div className="call-notification__calling-content">
            <div className="call-notification__calling-text">
              Incoming call...
            </div>
            <div className="call-notification__calling-number">
              (354) 520-8731
            </div>
          </div>
        </div>
      )}
      <div className="call-notification__footer">
        {isInCall && (
          <div
            className={classnames('call-notification__action-wrapper', {
              small: !isMobile,
              gray: !isMobile
            })}
          >
            <MuteCallIcon className="call-notification__action-icon-mute" />
          </div>
        )}
        {!isInCall && (
          <div
            className="call-notification__action-wrapper success"
            onClick={handleAnswer}
          >
            <AnswerCallIcon className="call-notification__action-icon-answer" />
            <span>Answer</span>
          </div>
        )}
        <div
          className={classnames('call-notification__action-wrapper danger', {
            small: isInCall && !isMobile
          })}
          onClick={handleDismiss}
        >
          <DismissCallIcon className="call-notification__action-icon-dismiss" />
          {!isInCall && <span>Dismiss</span>}
        </div>
      </div>
    </div>
  );
};

export default CallNotification;
