import React from 'react';
import PlayIcon from 'assets/image/icons/app/audio-play.svg';
import PauseIcon from 'assets/image/icons/app/audio-pause.svg';
import { ControlWrapper } from './style';

type TProps = {
  isPlaying: boolean,
  onPlayPauseClick: (flag: boolean) => void
}
const AudioControls: React.FC<TProps> = ({ isPlaying, onPlayPauseClick }) => (
  <ControlWrapper className="audio-control__actions">
    {isPlaying ? (
      <PauseIcon
        className="audio-control__pause"
        onClick={() => onPlayPauseClick(false)}
      />
    ) : (
      <PlayIcon
        className="audio-control__play"
        onClick={() => onPlayPauseClick(true)}
      />
    )}
  </ControlWrapper>
);

export default AudioControls;
