import styled from 'styled-components/macro';

export const ToggleWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const DropdownWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  .dropdown-menu {
    min-width: 13rem;
    top: -120px !important;
    box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.15);
    border-color: #DCE7EF;
    border-radius: 6px;

    .dropdown-item {
      padding: 0.5rem 1rem;
    }
  }
`;
