export const Weekdays = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

export interface IBusinessHours {
  id?: string;
  userId?: string;
  openHour: number;
  openMinute: number;
  closeHour: number;
  closeMinute: number;
  day: number;
  timezone: string;
  active: boolean;
}