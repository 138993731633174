import React, { useState, useEffect, useRef } from 'react';
import AudioControls from './audioControls';
import { PlayerWrapper } from './style';

type TProps = {
  className?: string,
  track: any,
}
const AudioPlayer: React.FC<TProps> = ({ track, ...props }) => {
  // State
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // Refs
  const audioRef = useRef<any>(new Audio(track));
  const intervalRef = useRef<number>();
  const isReady = useRef<boolean>(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentRatio = duration ? trackProgress / duration : 0;
  const currentPercentage = `${currentRatio * 100}%`;
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #04B3C0), color-stop(${currentPercentage}, #9646C3))
  `;

  const startTimer = (): void => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = window.setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 1000);
  };

  const onScrub = (value): void => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = (): void => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(track);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }

    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, [track]);

  return (
    <PlayerWrapper {...props}>
      <input
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max={duration || '0'}
        className="audio-track__progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
      />
      <div className="audio-track__times">
        <span className="audio-track__times-start">
          00.00
        </span>
        {Boolean(trackProgress) && Boolean(currentRatio) && (
          <span
            className="audio-track__times-floating"
            style={{
              left: currentPercentage,
              marginLeft: -30 * currentRatio,
            }}
          >
            {trackProgress?.toFixed(2)}
          </span>
        )}
        {Boolean(duration) && (
          <span className="audio-track__times-end">
            {duration?.toFixed(2)}
          </span>
        )}
      </div>
      <AudioControls
        isPlaying={isPlaying}
        onPlayPauseClick={setIsPlaying}
      />
    </PlayerWrapper>
  );
};

export default AudioPlayer;
