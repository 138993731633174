import React from 'react';
import classnames from 'classnames';
import { ProgressBarWrapper, Step } from './style';

type Props = {
  steps?: number,
  focus?: number,
  className?: string,
}
const ProgressBar: React.FC<Props> = ({ steps = 3, focus = 1, className }) => (
  <ProgressBarWrapper className={className}>
    {steps > 0 ? (
      <>
        {Array.from(Array(steps), (_, index) => (
          <Step
            key={`${index}`}
            className={classnames('progressbar-step', {
              active: index + 1 <= focus,
            })}
          />
        ))}
      </>
    ) : null}
  </ProgressBarWrapper>
);

export default ProgressBar;
