import { useState, useEffect } from 'react';

interface IFormErrors {
  [key: string]: string;
}

const useForm = (
  onSuccess: (args: any) => void,
  validate: (args: any) => IFormErrors,
  initialState?: any
) => {
  const [values, setValues] = useState<any>(initialState || {});
  const [errors, setErrors] = useState<any>({});
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!!Object.keys(errors).length && isSubmitting) {
      setIsSubmitting(false);
    }

    if (!Object.keys(errors).length && isSubmitting && isValidated) {
      onSuccess(values);
    }
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, errors, isSubmitting, isValidated]);

  useEffect(() => {
    setValues(initialState || {});
    setIsValidated(false);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  const handleSubmit = (event): void => {
    if (event) {
      event.preventDefault();
    }
    setErrors(validate(values));
    setIsValidated(true);
    setIsSubmitting(true);
  };

  const handleChange = (event): void => {
    event.persist();

    setIsSubmitting(false);
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const updateValues = (newValues) => {
    setValues(values => ({
      ...values,
      ...newValues
    }));
  };

  const handleSelectChange = (key, value): void => {
    const data = { ...values };
    data[key] = value;
    setValues(data);
  };

  const initiate = (): void => {
    setValues(initialState || {});
    setIsValidated(false);
    setErrors({});
    setIsSubmitting(false);
  };

  return {
    handleChange,
    handleSelectChange,
    updateValues,
    handleSubmit,
    initiate,
    values,
    errors,
    isValidated,
  };
};

export default useForm;
