import React, { useContext, useState } from 'react';
import Toggle from 'react-toggle';

import GlobalAppContext from 'context/globalAppContext';
import { SET_SIDEBAR, SET_SOFTPHONE } from 'context/actions';
import { useIsMobile } from 'components/hooks';
import SoftphoneIcon from 'assets/image/icons/app/softphone.svg';
import PhoneCallIcon from 'assets/image/icons/app/phone.svg';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';
import ProgressCallIcon from 'assets/image/icons/app/progress-call.svg';
import AnswerCallIcon from 'assets/image/icons/app/answer-call.svg';
import DismissCallIcon from 'assets/image/icons/app/dismiss-call.svg';
import MuteCallIcon from 'assets/image/icons/app/mute-call.svg';
import DialCallIcon from 'assets/image/icons/app/dial-call.svg';
import VolumeCallIcon from 'assets/image/icons/app/volume-call.svg';
import ContactIcon from 'assets/image/icons/app/contact.svg';
import BackspaceIcon from 'assets/image/icons/app/backspace.svg';

type PanelProps = {
  isInCall?: boolean,
}
export const SoftphonePanel: React.FC<PanelProps> = ({ isInCall }) => {
  const [number, setNumber] = useState('');

  const addNumber = (e: React.MouseEvent, num: string): void => {
    e.stopPropagation();

    setNumber((prevState: string): string => `${prevState}${num}`);
  };

  const backNumber = (e: React.MouseEvent): void => {
    e.stopPropagation();

    setNumber((prevState: string): string => `${prevState.slice(0, -1)}`);
  };

  return (
    <div className="main-softphone">
      {isInCall ? (
        <>
          <div className="main-softphone__callpad">
            <div className="main-softphone__callpad-progress">
              <ProgressCallIcon className="main-softphone__callpad-icon-progress" />
            </div>
            <div className="main-softphone__callpad-number">
              +1 920 800 9291
            </div>
            <div className="main-softphone__callpad-time">
              01:29
            </div>
          </div>
          <div className="main-softphone__actions">
            <div className="main-softphone__actions-item">
              <MuteCallIcon />
            </div>
            <div className="main-softphone__actions-item">
              <DialCallIcon />
            </div>
            <div className="main-softphone__actions-item">
              <VolumeCallIcon />
            </div>
          </div>
        </>
      ) : (
        <div className="main-softphone__dialpad">
          <div className="main-softphone__dialpad-number">
            <div className="main-softphone__dialpad-numbers">
              {number}
            </div>
            <div
              className="main-softphone__dialpad-backspace"
              onClick={backNumber}
            >
              <BackspaceIcon />
            </div>
          </div>
          <div className="main-softphone__dialpad-status">
            <p className="main-softphone__dialpad-status-text">Calling...</p>
          </div>
          <div className="main-softphone__dialpad-pad">
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '1')}
            >
              1
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '2')}
            >
              2
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '3')}
            >
              3
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '4')}
            >
              4
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '5')}
            >
              5
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '6')}
            >
              6
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '7')}
            >
              7
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '8')}
            >
              8
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '9')}
            >
              9
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '*')}
            >
              *
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '0')}
            >
              0
            </div>
            <div
              className="main-softphone__dialpad-dial"
              onClick={(e) => addNumber(e, '#')}
            >
              #
            </div>
          </div>
        </div>
      )}
      <div className="main-softphone__footer">
        {!isInCall && (
          <div className="main-softphone__footer-contact">
            <ContactIcon />
          </div>
        )}
        {isInCall ? (
          <div className="main-softphone__action-wrapper danger">
            <DismissCallIcon className="main-softphone__action-icon-dismiss" />
          </div>
        ) : (
          <div className="main-softphone__action-wrapper success">
            <AnswerCallIcon className="main-softphone__action-icon-answer" />
          </div>
        )}
      </div>
    </div>
  );
};

const Softphone: React.VFC = () => {
  const [isIncoming, setIsInComing] = useState(false);
  const isMobile = useIsMobile();
  const { dispatch } = useContext(GlobalAppContext);

  const handleClick = () => {
    dispatch({
      type: SET_SIDEBAR,
      payload: { open: false }
    });
    dispatch({
      type: SET_SOFTPHONE,
      payload: { open: true }
    });
  };

  return (
    <>
      {isMobile && (
        <div
          className="softphone__toggler"
          onClick={handleClick}
        >
          <SoftphoneIcon />
        </div>
      )}
      <div className="softphone__status">
        <Toggle
          className="softphone__status-toggle"
          defaultChecked={isIncoming}
          icons={{
            checked: <VoicioIcon />,
            unchecked: <PhoneCallIcon />,
          }}
          onChange={() => setIsInComing(!isIncoming)}
        />
      </div>
    </>
  );
};

export default Softphone;
