import { useState, useEffect } from 'react';

const DESKTOP_SIZE = 1199.98;

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const isNewValue = window.innerWidth < DESKTOP_SIZE;
      setIsDesktop(isNewValue);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isDesktop;
};

export default useIsDesktop;
