import React from 'react';
import { toast } from 'react-toastify';

const useToast = () => {
  const Content = ({ title, text }) => (
    <div className="Toastify__toast-content">
      <p className="Toastify__toast-content-title">{title}</p>
      <p className="Toastify__toast-content-text">{text}</p>
    </div>
  );

  const trigger = ({
    type,
    title,
    text,
    autoClose = 8000,
    callback = () => {},
  }): void => {
    toast(<Content title={title} text={text} />, {
      position: toast.POSITION.TOP_CENTER,
      theme: 'colored',
      type,
      autoClose,
      onClose: callback
    });
  };

  return trigger;
};

export default useToast;
