import { IRestEndpoints, EApiRoutes, Customer, EQueryKeys } from 'interfaces';
import { createDefaultRequests} from 'data/utils';

export const customerForm: Customer.IForm = {
  name: '',
  phone: '',
  addressLine1: '',
};

export const restEndpoints: IRestEndpoints = createDefaultRequests
  <Customer.IShape>(
    EQueryKeys.CustomerClients,
    EApiRoutes.CustomersClients,
    EApiRoutes.CustomerClientsDetail
  );

export const validate = (customer: Customer.IShape) => {
  const validatedCustomer = {};
  Object.entries(customer).forEach(([key, value]) => {
    if (!value) {
      if (Customer.Validation[key].required) {
        // TODO: deal with error
      }
    } else {
      // TODO: validate type and typecheck
      validatedCustomer[key] = value;
    }
  });
  return validatedCustomer;
};




