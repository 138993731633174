import { useState } from 'react';

const useModal = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const toggle = (): void => setModalOpen(!isModalOpen);

  const showModal = (): void => setModalOpen(true);

  const hideModal = (): void => setModalOpen(false);

  return {isModalOpen, showModal, hideModal, toggle};
};

export default useModal;
