type TProps = {
  r: number,
  g: number,
  b: number,
}
export const hexToRgb = (hex): TProps | null => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

export const rgba = (hex, alpha): string => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const hashString = (string?: string): number => {
  let hash = 0;
  if (!string) return hash;
  if (!string.length) return hash;
  if (!string || (string.length === 0)) return hash;
  for (let i = 0; i < string.length; i++) {
    const chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getAvatarUrl = (str: string) => {
  const hash = hashString(str);
  return `https://avatarcdn.voicio.com/spaceinvaders/${hash}?theme=duskfalling&numcolors=4&size=220&fmt=svg`;
};

export const formatNumber = (num) => {
  const areaCode = num.slice(1, 4);
  const firstDigits = num.slice(4, 7);
  const lastDigits = num.slice(7, 11);
  return `(${areaCode}) ${firstDigits}-${lastDigits}`;
};

export const formatAddressLine = ({
  addressLine1,
  addressLine2 = null,
  city,
  state,
  country,
  zipCode
}) => {
  let formattedAddress = addressLine1;
  if (addressLine2)
    formattedAddress += `, ${addressLine2}`;
  if (city)
    formattedAddress += `, ${city}`;
  if (state)
    formattedAddress += `, ${state}`;
  if (zipCode)
    formattedAddress += ` ${zipCode}`;
  if (country)
    formattedAddress += ` ${country}`;

  return formattedAddress;
};
