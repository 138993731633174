import React from 'react';
import { TabsWrapper } from './style';

const Tabs = ({ children, ...props }) => (
  <TabsWrapper {...props}>
    {children}
  </TabsWrapper>
);

export default Tabs;
