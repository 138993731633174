import React from 'react';
import classnames from 'classnames';
import { Modal } from 'react-bootstrap';
import { ModalWrapper } from './style';

type Props = {
  className?: string,
  show: boolean,
  onHide: () => void,
  title: any,
  children: any,
  footer?: any,
  left?: boolean,
  right?: boolean,
}
const CustomModal: React.FC<Props> = ({
  title, children, footer, left = false, right, ...props
}) => (
  <ModalWrapper className={classnames({
    left,
    right,
  })} {...props}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    {footer && (
      <Modal.Footer>
        {footer}
      </Modal.Footer>
    )}
  </ModalWrapper>
);

export default CustomModal;
