import styled from 'styled-components/macro';

export const Step = styled.li`
  width: 25px;
  height: 4px;
  background-color: #D8D8D8;
  border-radius: 2.5px;
  margin: 0 3px;
`;

export const ProgressBarWrapper = styled.ul`
  width: 100%;
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  ${Step} {
    list-style-type: none;
    float: left;
    position: relative;

    &.active {
      background-color: #04B3C0;
    }
  }

  &.full {
    .progressbar-step {
      width: 100%;
      border-radius: 0;
      margin: 0;
    }
  }
`;
