import CallsIcon from 'assets/image/icons/app/calls.svg';
import CalendarCapacityIcon from 'assets/image/icons/app/calendar-capacity.svg';
import ReceivedCallsIcon from 'assets/image/icons/app/received-calls.svg';
import SavedCallsIcon from 'assets/image/icons/app/saved-calls.svg';

export const statsData = [
  {
    Icon: CallsIcon,
    title: 'Active Calls',
    value: '6',
    count: 163,
    color: '#F0F4F8'
  },
  {
    Icon: CalendarCapacityIcon,
    title: 'Calendar Capacity',
    value: '98%',
    count: 163,
    color: '#FFF8E1'
  },
  {
    Icon: ReceivedCallsIcon,
    title: 'Calls Taken Today',
    value: '12',
    count: -45,
    color: '#F9EEFF'
  },
  {
    Icon: SavedCallsIcon,
    title: 'Time Saved From Calls',
    value: '362',
    color: '#FFFFFF'
  },
];

export const overviewData = {
  labels: [
    'Feb 6', 'Feb 7', 'Feb 8', 'Feb 9', 'Feb 10', 'Feb 11', 'Feb 12', 'Feb 13'
  ],
  datasets: [
    {
      label: 'Booked Appointments (10)',
      data: [20, 10, 20, 30, 50, 40, 30, 10],
      backgroundColor: '#7107C2',
      borderColor: '#7107C2',
      order: 1
    },
    {
      label: 'Rescheduled (1)',
      data: [30, 20, 20, 10, 10, 20, 30, 30],
      backgroundColor: '#FDB404',
      borderColor: '#FDB404',
      order: 2
    },
    {
      label: 'Cancelled (1)',
      data: [0, 0, 0, 0, 5, 2, 0, 0],
      backgroundColor: '#EE6352',
      borderColor: '#EE6352',
      order: 2
    },
  ],
};

export const searchData = [
  {
    avatar: 'https://avatarcdn.voicio.com/spaceinvaders/0?theme=duskfalling&numcolors=4&size=220&fmt=svg',
    name: 'John Doe',
    company: 'RiverValley Construction',
    address: '1701 California St, Denver, CO',
  },
  {
    avatar: 'https://avatarcdn.voicio.com/spaceinvaders/0?theme=duskfalling&numcolors=4&size=220&fmt=svg',
    name: 'John Denver',
    company: 'RiverValley Construction',
    address: '1701 California St, Denver, CO',
  },
  {
    avatar: 'https://avatarcdn.voicio.com/spaceinvaders/0?theme=duskfalling&numcolors=4&size=220&fmt=svg',
    name: 'John Dolly',
    company: 'RiverValley Construction',
    address: '1701 California St, Denver, CO',
  },
];
