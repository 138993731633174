export interface IShape {
  id?: number;
  customer: any;
  type: ETypes | string;
  activity: EActivities | string;
  summary?: string;
  outcome: IOutcome;
  date?: string;
  [key: string]: any;
}

export interface IOutcome {
  id?: number;
  name?: string;
}

export enum ETypes {
  User,
  Voicio
}

export enum EActivities {
  Call,
  Message
}
