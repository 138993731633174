import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Nav } from 'react-bootstrap';

import GlobalAppContext from 'context/globalAppContext';
import { SET_SIDEBAR } from 'context/actions';

type Props = {
  item: any,
  className?: string,
}
const NavItem: React.FC<Props> = ({ item, ...props }) => {
  const { Icon } = item;

  const { dispatch } = useContext(GlobalAppContext);

  const handleClick = () => {
    dispatch({
      type: SET_SIDEBAR,
      payload: { open: false }
    });
  };

  return (
    <Nav.Item {...props}>
      <Nav.Link eventKey={item.to} as={Link} to={item.to} onClick={handleClick}>
        {Icon && (
          <div className="nav-link-icon">
            <Icon />
          </div>
        )}
        {item.label && <span>{item.label}</span>}
        {item.count && <span className="nav-link-badge">{item.count}</span>}
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  item: PropTypes.object
};

export default NavItem;
