import { IRestEndpoints, ECaldavRoutes, Calendar, ERestMethods } from 'interfaces';
import { CALDAV_URL } from 'data/config';
import { sendRequest } from 'data/utils';
import moment from 'moment';

export const emptyForm: Calendar.IEventForm = {
  start: new Date(),
  end: moment().add('1', 'hour'),
  service: '',
  location: '',
  organizer: {
    email: '',
    name: '',
    phone: ''
  },
  // alarms: any,
  // _status: any,
};

export const validate = (calendar: Calendar.IShape) => {
  const validatedCalendar = {};
  Object.entries(calendar).forEach(([key, value]) => {
    if (!value) {
      if (calendar.Validation[key].required) {
        // TODO: deal with error
      }
    } else {
      // TODO: validate type and typecheck
      validatedCalendar[key] = value;
    }
  });
  return validatedCalendar;
};

export const get = (authUser) => async () => {
  const events =
    await sendRequest({
      url: CALDAV_URL,
      route: ECaldavRoutes.Events,
      method: ERestMethods.POST,
      token: authUser.token,
      body: {
        token: authUser.token
      },
    });

  return events.map(e => ({
    ...e,
    id: e.uid,
    summary: decodeURIComponent(e.summary)
  }));
};

export const create = authUser => async (event: Calendar.IEventForm) => {
  const response =
    await sendRequest({
      url: CALDAV_URL,
      route: ECaldavRoutes.CreateEvent,
      method: ERestMethods.POST,
      token: authUser.token,
      body: {
        token: authUser.token,
        ...event
      }
    });
  return response;
};

export const update = (authUser) => async (event: Calendar.IEventForm) => {
  const response = await
  sendRequest({
    url: CALDAV_URL,
    route: ECaldavRoutes.UpdateEvent,
    method: ERestMethods.POST,
    token: authUser.token,
    body: {
      token: authUser.token,
      ...event
    }
  });
  return response;
};

export const deleteData = (authUser) => async (event: Calendar.IEventForm) => {
  const response = await
  sendRequest({
    url: CALDAV_URL,
    route: ECaldavRoutes.EventDetail,
    method: ERestMethods.DELETE,
    token: authUser.token,
    body: {
      token: authUser.token,
      ...event
    }
  });
  return response;
};

export const restEndpoints: IRestEndpoints = {
  get,
  create,
  update,
  delete: deleteData,
};


