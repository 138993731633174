
import { selector, atom, useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby';
import { EQueryKeys, Auth, ELocalStorageKeys } from 'interfaces';
import { queryClient } from 'context/globalAppContext';
import { getRefreshToken, checkAuth } from './';


export const AuthUserState = atom({
  key: EQueryKeys.AuthUser,
  default: selector({
    key: EQueryKeys.LocalAuthUser,
    get: async (): Promise<Auth.IUser | Auth.IAuthStatus> => {
      const user = await localStorage.getItem(ELocalStorageKeys.AuthUser);
      if (user) {
        const authUser: Auth.IUserInfo = JSON.parse(user);
        try {
          await checkAuth(authUser.token);
          return {
            ...authUser,
            authenticated: true,
            loaded: true
          };
        } catch (err) {
          if (err.message === 'REFRESH_TOKEN') {
            const accessToken = await getRefreshToken(
              authUser.refreshToken
            );
            const newTokenUser = {
              ...authUser,
              token: accessToken
            };
            localStorage
              .setItem(ELocalStorageKeys.AuthUser,
                JSON.stringify(newTokenUser)
              );
            return {
              ...newTokenUser,
              authenticated: true,
              loaded: true
            };
          }
        }
      }
      return {
        authenticated: false,
        loaded: true,
      };
    }
  })
});


export const useSignOut = (changeLocation = navigate) => {
  const setUserState = useSetRecoilState(AuthUserState);

  const logOut = () => {
    localStorage
      .removeItem(ELocalStorageKeys.AuthUser);
    queryClient.clear();
    setUserState({
      authenticated: false,
      loaded: true
    });
    changeLocation('/');
  };

  return {
    logOut
  };
};

export * from './endpoints';