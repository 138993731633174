import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Nav } from 'react-bootstrap';

const NavItem = ({ item, ...props }) => {
  const { Icon } = item;

  return (
    <Nav.Item {...props}>
      <Nav.Link eventKey={item.to} as={Link} to={item.to}>
        <div className="nav-link-icon">
          <Icon />
        </div>
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  item: PropTypes.object
};

export default NavItem;
