import React, { useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from 'react-table';
import classnames from 'classnames';
import { Checkbox, Searchbox, Pagination } from 'components';
import { TableWrapper } from './style';
import FilterIcon from 'assets/image/icons/app/filter.svg';

type Props = {
  title?: string,
  columns: any,
  data: any,
  filterable?: boolean,
  paginatable?: boolean,
  selectable?: boolean,
  rowsPerPage?: number,
  filter?: any,
}
const Table: React.FC<Props> = ({
  title,
  columns,
  data,
  filterable = false,
  paginatable = false,
  selectable = false,
  rowsPerPage = 100,
  filter = null,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: rowsPerPage },
  }, useGlobalFilter, usePagination, useRowSelect, hooks => {
    if (selectable) {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          width: 60,
        },
        ...columns,
      ]);
    }
  });

  const [query, setQuery] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TableWrapper {...props}>
      <div className="main-table__header">
        <h4>{title || ''}</h4>
        {filterable && !filter && (
          <div className="main-table__header-filter">
            <div className="main-table__header-filter-button me-2">
              <FilterIcon />
            </div>
            <Searchbox
              className="main-table__header-filter-input"
              value={query || ''}
              placeholder="Search..."
              onChange={e => {
                setQuery(e.target.value);
                onChange(e.target.value);
              }}
            />
          </div>
        )}
      </div>
      {filter}
      <table className="main-table__content" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, k) => (
                <th
                  key={k}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()} className={classnames({
                selected: row.isSelected
              })}>
                {row.cells.map((cell, k) => (
                  <td key={k} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {paginatable && (
        <div className="main-table__footer">
          <div className="main-table__footer-pagination">
            <Pagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </div>
          <div className="main-table__footer-info">
            Showing {page.length} of {data.length}{' '}
          </div>
        </div>
      )}
    </TableWrapper>
  );
};

export default Table;
