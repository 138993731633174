import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AuthUserState } from 'data/auth';
import { ErrorState } from 'data/error';
import apiEndpoints from 'data/endpoints';
import { createOptions, updateOptions } from './helpers';
import {
  EQueryKeys,
  IRestEndpoints,
  IMutationController,
  IError,
  ERefetchPolicies,
  RefetchPolicies,
  Auth,
  ILoggerArgs
} from 'interfaces';

const useConnector = (
  endpointKey: EQueryKeys,
  {
    active = true,
    logging = false,
    refetchPolicy = ERefetchPolicies.Light
  } = {}
) => {
  const queryClient = useQueryClient();
  const authUserLoadable = useRecoilValueLoadable(AuthUserState);
  const [errorState, updateErrorState] = useRecoilState(ErrorState);

  const restEndpoints: IRestEndpoints = apiEndpoints[endpointKey];

  const authUser: Auth.IUser = authUserLoadable?.state === 'hasValue'
    && authUserLoadable.contents as Auth.IUser;

  const handleError = (error: IError) =>
    updateErrorState({ [error.type]: error });

  const queryConfig = {
    retry: false,
    enabled: !!authUser && active,
    onError: handleError,
    ...RefetchPolicies[refetchPolicy],
  };
  const queryKey = [endpointKey, authUser.id];
  const { data, isLoading } = useQuery(
    queryKey,
    restEndpoints.get(authUser),
    queryConfig,
  );

  const logger = (type, { data, response, context }: ILoggerArgs) => {
    if (logging) {
      if (data) console.log(`${endpointKey}/${type}/Data`, data);
      if (response) console.log(`${endpointKey}/${type}/Response`, response);
      if (context) console.log(`${endpointKey}/${type}/Context`, context);
    }
  };

  const createMutation = useMutation(
    restEndpoints.create(authUser),
    createOptions({ queryClient, queryKey, handleError, logger })
  );

  const updateMutation = useMutation(restEndpoints.update(authUser),
    updateOptions({ queryClient, queryKey, handleError, logger })
  );

  const deleteMutation = useMutation(restEndpoints.delete(authUser), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey,
        (oldData: Array<any>) =>
          oldData.filter(c => c.id !== updatedData.id)
      );
    },
    onError: handleError
  });

  const mutationController: IMutationController = {
    onCreate: (newData) => {
      createMutation.mutate(newData);
    },
    onUpdate: (newData) => {
      updateMutation.mutate(newData);
    },
    onDelete: (newData) => {
      deleteMutation.mutate(newData);
    },
  };

  const response = {
    data: data || [],
    isLoading,
    mutationController,
    authUser
  };

  if (active && logging) {
    console.log(`${endpointKey} returning:`, data);
  }

  return response;
};

export default useConnector;
