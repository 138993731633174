import React from 'react';
import { RatingWrapper, Star } from './style';

const Rating = ({ star = 5, ...props }) => {
  const starInt = Math.floor(star);
  const isHalfable = starInt !== star;

  return (
    <RatingWrapper {...props}>
      {star <= 5 ? (
        <>
          {Array.from(Array(starInt), (_, index) => (
            <Star className="full" key={`${index}fs`} />
          ))}
          {isHalfable && (
            <Star className="half" />
          )}
          {Array.from(Array(5 - starInt - (isHalfable ? 1 : 0)), (_, index) => (
            <Star key={`${index}es`} />
          ))}
        </>
      ) : null}
    </RatingWrapper>
  );
};

export default Rating;
