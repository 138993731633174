import React from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from 'assets/image/logo/logo.png';
import Menu from './menu';
import HamburgerIcon from 'assets/image/icons/home/hamburger.svg';

type Props = {
  customLogo?: string,
  defaultLogo?: string,
}
const SiteNavbar: React.FC<Props> = ({ customLogo, defaultLogo }) => (
  <Navbar
    collapseOnSelect
    expand="lg"
    bg="dark"
    variant="dark"
    className="site-navbar"
  >
    <Navbar.Brand href="/">
      {defaultLogo ? (
        <img src={defaultLogo} alt="site-brand" />
      ) : customLogo ? (
        <img src={customLogo} alt="site-brand" />
      ) : (
        <img src={Logo} alt="site-brand" />
      )}
    </Navbar.Brand>
    <Navbar.Toggle
      aria-controls="responsive-navbar-nav"
      className="hamburger"
    >
      <HamburgerIcon />
    </Navbar.Toggle>
    <Menu />
  </Navbar>
);

export default SiteNavbar;
