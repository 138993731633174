import React from 'react';

export enum EAtomKeys {
  AuthUser = 'AuthUser',
  Error = 'Error'
}

export enum ELocalStorageKeys {
  AuthUser = 'AuthUser'
}

export interface IAuthRoute {
  name: string;
  path: string;
  Component: React.FC<any>
}

export interface IError {
  message: string;
  status: number;
  type: EErrorTypes;
}

export enum EErrorTypes {
  Global = 'Global',
  Auth = 'Auth',
  Token = 'Token',
  Caldav = 'Caldav'
}

export enum EApiErrors {
  CaldavUnauthorized = 'Invalid Username/Password'
}

export enum ELoadableScripts {
  GoogleMaps = 'GOOGLE_MAPS'
}

export * as Auth from './auth';
export * as Customer from './customers';
export * as Calendar from './calendar';
export * as VReceptionist from './virtualReceptionist';
export * from './businessHours';
export * as Interactions from './interactions';
export * from './form';
export * as Phone from './phone';
export * from './api';
export * from './constants';
