import styled from 'styled-components/macro';

const AuthWrapper = styled.div.attrs({
  className: 'auth-wrapper',
  id: 'auth-wrapper',
})`
  width: 100%;
  min-height: 100vh;
  background-color: #E1EBF0;
`;

AuthWrapper.Content = styled.div.attrs({
  className: 'auth-wrapper-content',
})`
  position: relative;
  width: calc(100vw - 50px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  text-align: center;

  @media (min-width: 575px) {
    width: 420px;
    padding-top: ${props => (props.paddingTop ? props.paddingTop : '140px')};
  }
`;

export default AuthWrapper;
