import styled from 'styled-components/macro';

export const PlanWrapper = styled.div`
  background-color: #FBF3FF;
  border-radius: 12px;
  text-align: center;
  padding: 18px 20px;

  svg {
    margin-top: -50px;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 15px;
    line-height: 18px;
    color: #1D1D1D;
  }

  p {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #6B4B7D;
    margin-bottom: 11px;

    span {
      text-decoration: underline;
    }
  }

  button {
    font-size: 13px;
    line-height: 14px;
    padding: 8px 14px;
  }

  @media (max-width: 575.98px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 15px 16px;
    text-align: left;

    svg {
      min-width: 35px;
      margin-top: 0;
      margin-left: 10px;
    }

    h6 {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
