import styled from 'styled-components/macro';

export const CheckboxWrapper = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 13px;
  width: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 4px;

    &:before {
      content: '';
      position: absolute;
      display: none;
      left: 2px;
      top: 5px;
      width: 7px;
      height: 2px;
      background-color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      border: solid #FFFFFF;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    background-color: #fafafa;
  }

  input:checked ~ .checkmark {
    background-color: #04B3C0;
    border: 1px solid #04B3C0;

    &:after {
      display: block;
    }
  }

  input:indeterminate ~ .checkmark {
    background-color: #04B3C0;
    border: 1px solid #04B3C0;

    &:before {
      display: block;
    }
  }

  &.is-valid .checkmark {
    border-color: #04b3c0;
  }

  &.is-invalid .checkmark {
    border-color: #ef2323;
  }
`;

export const SearchboxWrapper = styled.div`
  position: relative;
  height: 30px;
  background: #FFFFFF;
  border-radius: 10px;

  svg {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 13px;
    user-select: none;
  }

  input {
    -webkit-appearance: none;
    background: inherit;
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #DCE7EF;
    border-radius: 10px;
    padding: 7px 16px 5px 34px;
    outline: none;
    box-shadow: none;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #BBC5C5;
    }
  }
`;

export const Star = styled.li`
  font-size: inherit;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
`;

export const RatingWrapper = styled.div.attrs(props => ({
  as: 'ul',
  className: `rating-wrapper ${props.className || ''}`,
}))`
  display: inline-flex;
  font-size: 15px;
  margin: 0;
  padding: 0;
  list-style: none;

  ${Star} {
    margin: 0 1px;
    color: #E3E3E3;
    &:before {
      content: '\f005';
    }
    &.full {
      color: #FFB600;
    }
    &.half {
      color: #FFB600;
      &:after {
        content: '\f089';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;
