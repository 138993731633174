import React from 'react';
// import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
// import { menuItems } from 'data/menus';
// import Link from '../link';

const Menu: React.VFC = ({ ...rest }) => (
  <>
    {/* <Navbar.Collapse id="responsive-navbar-nav" {...rest}>
      <Nav className="site-menu-main">
        {menuItems.map(
          ({ label, isExternal = false, name, to, items, ...rest }, index) => {
            const hasSubItems = Array.isArray(items);

            return (
              <React.Fragment key={name + index}>
                {hasSubItems && (
                  <NavDropdown
                    title={label}
                    id={name + index}
                    className="main-dropdown main-nav-item"
                    renderMenuOnMount
                    {...rest}
                  >
                    {items.map((subItem, indexSub) => {
                      const hasInnerSubItems = Array.isArray(subItem.items);
                      return (
                        <React.Fragment key={subItem.name + indexSub}>
                          {hasInnerSubItems ? (
                            <NavDropdown
                              title={subItem.label}
                              id={subItem.name + indexSub}
                              renderMenuOnMount
                              className="drop-menu-item innerDropdown"
                            >
                              {subItem.items.map(
                                (itemInner, indexInnerMost) => (
                                  <Nav.Item
                                    className="drop-menu-item"
                                    key={itemInner.name + indexInnerMost}
                                  >
                                    {itemInner.isExternal ? (
                                      <NavDropdown.Item
                                        href={itemInner.to}
                                        target="_blank"
                                      >
                                        {itemInner.label}
                                      </NavDropdown.Item>
                                    ) : (
                                      <Link to={itemInner.to}>
                                        {itemInner.label}
                                      </Link>
                                    )}
                                  </Nav.Item>
                                ),
                              )}
                            </NavDropdown>
                          ) : (
                            <>
                              {subItem.isExternal ? (
                                <Nav.Item className="drop-menu-item">
                                  <NavDropdown.Item
                                    href={subItem.to}
                                    target="_blank"
                                  >
                                    {subItem.label}
                                  </NavDropdown.Item>
                                </Nav.Item>
                              ) : (
                                <Nav.Item className="drop-menu-item">
                                  <Link to={subItem.to}>
                                    {subItem.label}
                                  </Link>
                                </Nav.Item>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </NavDropdown>
                )}
                {!hasSubItems && (
                  <>
                    {isExternal ? (
                      <Nav.Item className="main-nav-item">
                        <Nav.Link
                          className="nav-link"
                          href={to}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {label}
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      <Nav.Item className="main-nav-item">
                        <Link
                          className="nav-link"
                          to={to}
                          role="button"
                          aria-expanded="false"
                        >
                          {label}
                        </Link>
                      </Nav.Item>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          },
        )}
        <Nav.Item className="main-nav-item login-link">
          <Link
            className="nav-link"
            to={'/login'}
            role="button"
            aria-expanded="false"
          >
            Sign in
          </Link>
        </Nav.Item>
        <Nav.Item className="main-nav-item register-link-wrapper">
          <Link
            className="register-link nav-link"
            to={'/register'}
            role="button"
            aria-expanded="false"
          >
            Try For Free!
          </Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse> */}
  </>
);

export default Menu;
