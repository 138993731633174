import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'utils/helper';
import Link from '../link';

export const Button = styled.button.attrs(props => ({
  className: `btn ${props.className || ''} btn-${props.variant || 'primary'}`,
}))`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => (props.sizeX ? props.sizeX : null)};
  width: ${props => (props.block ? '100%' : null)};
  height: ${props => (props.sizeY ? props.sizeY : null)};
  font-size: ${props => (props.fontSize ? props.fontSize : (props.large ? '16px' : '14px'))};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : (props.large ? '19px' : '14px'))};
  letter-spacing: ${props => props.letterSpacing};
  padding-top: ${props => (props.pt ? props.pt : (props.large ? '12px' : '9px'))};
  padding-bottom: ${props => (props.pb ? props.pb : (props.large ? '12px' : '9px'))};
  padding-left: ${props => (props.pl ? props.pl : (props.large ? '30px' : '20px'))};
  padding-right: ${props => (props.pr ? props.pr : (props.large ? '30px' : '20px'))};
  text-transform: ${props => props.textTransform ? props.textTransform : null};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  color: ${props => props.textColor ? props.textColor : null};
  background: ${props => props.backgroundColor ? props.backgroundColor : null};
  border-color: ${props => props.borderColor || props.backgroundColor || null};
  border-radius: ${props => (props.radius ? props.radius : (props.round ? '24px' : '4px'))};

  @media (min-width: 480px) {
    margin-top: ${props => props.mtXS};
    margin-bottom: ${props => props.mbXS};
    min-width: ${props => props.sizeXXS};
    height: ${props => (props.sizeYXS ? props.sizeYXS : null)};
    font-size: ${props => (props.fontSizeXS ? props.fontSizeXS : null)};
  }
  @media (min-width: 575px) {
    margin-top: ${props => props.mtSM};
    margin-bottom: ${props => props.mbSM};
    min-width: ${props => props.sizeXSM};
    height: ${props => (props.sizeYSM ? props.sizeYSM : null)};
    font-size: ${props => (props.fontSizeSM ? props.fontSizeSM : null)};
  }
  @media (min-width: 768px) {
    margin-top: ${props => props.mtMD};
    margin-bottom: ${props => props.mbMD};
    min-width: ${props => props.sizeXMD};
    height: ${props => (props.sizeYMD ? props.sizeYMD : null)};
    font-size: ${props => (props.fontSizeMD ? props.fontSizeMD : null)};
  }
  @media (min-width: 992px) {
    margin-top: ${props => props.mtLG};
    margin-bottom: ${props => props.mbLG};
    min-width: ${props => props.sizeXLG};
    height: ${props => (props.sizeYLG ? props.sizeYLG : null)};
    font-size: ${props => (props.fontSizeLG ? props.fontSizeLG : null)};
  }
  @media (min-width: 1200px) {
    margin-top: ${props => props.mtXL};
    margin-bottom: ${props => props.mbXL};
    min-width: ${props => props.sizeXXL};
    height: ${props => (props.sizeYXL ? props.sizeYXL : null)};
    font-size: ${props => (props.fontSizeXL ? props.fontSizeXL : null)};
  }

  &:hover, &:focus, &:active {
    color: ${props => props.hoverTextColor ? props.hoverTextColor : null};
    background: ${props => props.hoverBackgroundColor ? props.hoverBackgroundColor : null};
    border-color: ${props => props.hoverBorderColor || props.hoverBackgroundColor || null};
  }
  &:focus, &:active:focus {
    box-shadow: 0 0 0 0.2rem ${props => props.backgroundColor ? rgba(props.backgroundColor, 0.25) : null};
  }
`;

export const ButtonLink = ({ to, children, className, ...props }) => {
  return (
    <Link to={to || '/#'} className={className}>
      <Button {...props}>{children}</Button>
    </Link>
  );
};

export const FloatingButton = styled.div`
  position: fixed;
  width: 52px;
  height: 52px;
  right: 15px;
  bottom: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFBF00;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(167, 119, 0, 0.37);
  z-index: 999;
`;
