import React from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation } from '@reach/router';

import NavItem from './navItem';
import { menuItems } from 'data/sideMenus';

const Bottombar: React.VFC = () => {
  const location = useLocation();

  const mainItems = menuItems.filter(item => item.category === 'menu');

  return (
    <div className="main-bottombar">
      <Nav className="flex-column" activeKey={location.pathname}>
        {mainItems.map((item, idx) => (
          <NavItem
            key={idx}
            item={item}
          />
        ))}
      </Nav>
    </div>
  );
};

export default Bottombar;
