import { restEndpoints as customerEndpoints } from './pages/app/customers';
import { restEndpoints as calendarEndpoints } from './pages/app/bookingCalendar';
import {
  businessHoursEndpoints,
  callLogsEndpoint,
  aiVoicesEndpoint,
  greetingEndpoints
} from './pages/app/virtualReceptionist';
import interactionsEndpoint from './pages/app/interactions';
import { EQueryKeys } from 'interfaces';

export default {
  [EQueryKeys.CustomerClients]: customerEndpoints,
  [EQueryKeys.Calendar]: calendarEndpoints,
  [EQueryKeys.BusinessHours]: businessHoursEndpoints,
  [EQueryKeys.AiVoices]: aiVoicesEndpoint,
  [EQueryKeys.CallLogs]: callLogsEndpoint,
  [EQueryKeys.Greetings]: greetingEndpoints,
  [EQueryKeys.BusinessHours]: businessHoursEndpoints,
  [EQueryKeys.Interactions]: interactionsEndpoint
};
