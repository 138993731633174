import React, { createContext, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

import reducer from './reducer';
import defaultState from './defaultState';

const GlobalAppContext = createContext({});
const queryClient = new QueryClient();

const GlobalAppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...defaultState });

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <GlobalAppContext.Provider value={{ state, dispatch }}>
          {children}
        </GlobalAppContext.Provider>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider >
  );
};

export default GlobalAppContext;
export { GlobalAppProvider, queryClient };
