import styled from 'styled-components/macro';

export const TitleWrapper = styled.div.attrs(props => ({
  className: `page-title ${props.className || ''}`,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .page-title {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #1D1D1D;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin-bottom: 0;
    }

    &__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #839393;
      margin-bottom: 0;
    }

    &__action {
      &-link {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #7F9CB1;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &-text {
          text-decoration: underline;
        }
      }
    }

    &__back {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #839393;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }
`;
