
import { Auth, ELocalStorageKeys, EApiRoutes, ERestMethods } from 'interfaces';
import { getHeaders, handleError, sendRequest } from 'data/utils';
import { API_URL } from '../config';
import { getAvatarUrl } from 'utils/helper';

const parseUser = (authUser): Auth.IUser => {
  const userInfo = authUser.user;
  const avatar = getAvatarUrl(userInfo.phone);
  const stateUser = {
    ...authUser,
    ...userInfo,
    fullName: `${userInfo.first_name || ''} ${userInfo.last_name || ''}`,
    avatar
  };
  delete stateUser.user;
  return stateUser;
};

export const login =
  async (user: Auth.IUserInfo): Promise<Auth.IUser | Auth.IAuthStatus> => {
    try {
      const response = await sendRequest({
        route: EApiRoutes.Login,
        method: ERestMethods.POST,
        body: user
      });

      const stateUser = parseUser(response);
      localStorage
        .setItem(ELocalStorageKeys.AuthUser, JSON.stringify(stateUser));
      return {
        authenticated: true,
        loaded: true,
        ...stateUser
      };
    } catch (err) {
      return {
        authenticated: false,
        loaded: true,
      };
    }
  };


export const signUp =
  async (user: Auth.IUserInfo) => {
    const response = await fetch(API_URL + EApiRoutes.SignUp, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    });
    if (response.status === 200) {
      return await login({
        username: user.username,
        email: user.email,
        password: user.password
      });
    }
  };

export const validateEmail = async (email: string) => {
  const response = await fetch(API_URL + EApiRoutes.ValidateEmail, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email
    })
  });

  return response.status === 200;
};

export const sendEmailSSO = async ({
  email
}) => {
  try {
    const response = await fetch(API_URL + EApiRoutes.EmailSSO, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email
      })
    });

    return response.status === 200;
  } catch (err) {
    console.log('Error Sending User Email', err);
    return false;
  }
};

export const sendVerificationToken = async (
  { userId, token }: { userId: string, token: string }) => {
  const route = API_URL + EApiRoutes.VerifyToken
    .replace(':user_id', userId)
    .replace(':token', token);
  try {
    const response = await fetch(route);
    if (response.status === 200) {
      const user = await response.json();
      const stateUser = parseUser(user);
      localStorage
        .setItem(ELocalStorageKeys.AuthUser, JSON.stringify(stateUser));
      return {
        authenticated: true,
        loaded: true,
        ...stateUser
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log('error validating user', err);
    return false;
  }
};

export const sendResetPasswordEmail =
  async (email: string) => {
    const url = API_URL + EApiRoutes.SendResetPasswordEmail
      .replace(':email', email);
    const result = await fetch(url,
      {
        method: 'POST',
      });
    if (result.status === 200) {
      return true;
    } else {
      const data = await result.json();
      return data.done;
    }
  };

export const resetPassword =
  async ({ userId, token, password }:
    { userId: string, token: string, password: string }
  ) => {
    const url = API_URL + EApiRoutes.ResetPassword
      .replace(':user_id', userId)
      .replace(':token', token);
    const result = await fetch(url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password
        })
      });
    if (result.status === 200) {
      const data = await result.json();
      return data.done;
    } else {
      handleError(result);
    }
  };

export const getRefreshToken = async (token: string) => {
  const result = await fetch(API_URL + EApiRoutes.RefreshToken,
    {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(
        {
          refreshToken: token
        }
      )
    });
  const data = await result.json();
  return data.accessToken;
};

export const updateUser =
  async ({
    token,
    user
  },
  ) => {
    const url = API_URL + EApiRoutes.UpdateUser
      .replace(':user_id', user.id);
    const result = await fetch(url,
      {
        method: 'PATCH',
        headers: getHeaders(token),
        body: JSON.stringify(user)
      });
    if (result.status === 200 || result.status === 204) {
      return user;
    } else {
      const data = await result.json();
      if (data.error.message === 'Error verifying token : jwt expired') {
        throw new Error('REFRESH_TOKEN');
      }
    }
  };

export const checkAuth = async (token: string) => {
  const result = await fetch(API_URL + EApiRoutes.CheckAuth,
    {
      method: 'GET',
      headers: getHeaders(token),
    });
  if (result.status === 200) {
    return true;
  } else {
    const data = await result.json();
    if (data.error.message === 'Error verifying token : jwt expired') {
      throw new Error('REFRESH_TOKEN');
    }
  }
};