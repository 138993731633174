import styled from 'styled-components/macro';
import { Tabs } from 'react-bootstrap';

export const TabsWrapper = styled(Tabs)`
  padding: 0 15px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #DCE7EF;

  .nav {
    &-link {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #1D1D1D;
      padding: 12px 24px;

      &.active, &:hover, &:focus {
        color: #04B3C0;
        border-color: transparent transparent #04B3C0;
        border-bottom-width: 2px;
      }

      @media (max-width: 575px) {
        margin-bottom: 0;
        padding: 12px 20px;
      }
    }
  }

  &.nav-full {
    padding: 0 6px;
    .nav-item {
      flex: 1;

      button {
        width: 100%;
      }
    }
  }

  @media (max-width: 575.98px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 12px;

    * {
      flex-shrink: 0;
    }
  }
`;