import styled from 'styled-components/macro';

export const Label = styled.label`
  color: #1D1D1D;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 2px;
`;

export const Title = styled.label`
  color: #8A9DA4;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const Text = styled.p`
  color: #161616;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 9px 0;
  margin: 0;
`;

export const ErrorText = styled.p`
  color: #DD1717;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 6px;
  margin-bottom: 0;
`;

export const Input = styled.input`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #161616;
  border: 1px solid #D5D5D5;
  border-radius: ${props => (props.round ? '23px' : '5px')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0584299);
  padding: ${props => (props.round ? '8px 32px 8px 24px' : '8px 22px 8px 14px')};

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #818181;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 20px;
    padding: ${props => (props.round ? '15px 32px 13px 24px' : '15px 22px 13px 14px')};
  }
`;

export const Textarea = styled.textarea`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #161616;
  border: 1px solid #D5D5D5;
  border-radius: ${props => (props.round ? '23px' : '5px')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0584299);
  padding: ${props => (props.round ? '8px 24px 8px 24px' : '8px 14px 8px 14px')};

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #B1B1B1;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 20px;
    padding: ${props => (props.round ? '15px 24px 13px 24px' : '15px 14px 13px 14px')};
  }
`;

export const Select = styled.select`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4B595E;
  border: 1px solid #D5D5D5;
  border-radius: ${props => (props.round ? '23px' : '5px')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0584299);
  padding: ${props => (props.round ? '11px 32px 9px 24px' : '11px 22px 9px 14px')};
  appearance: ${props => props.noArrow ? false : 'auto'};
  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 20px;
    padding: ${props => (props.round ? '15px 32px 13px 24px' : '15px 22px 13px 14px')};
  }
`;

export const Append = styled.span`
  position: absolute;
  top: 50%;
  left: ${props => (props.right ? 'auto' : '15px')};
  right: ${props => (props.right ? '15px' : 'auto')};
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #BCBCBC;
`;
