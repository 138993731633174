import { useState, useEffect } from 'react';

const MOBILE_SIZE = 575.98;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const isNewValue = window.innerWidth < MOBILE_SIZE;
      setIsMobile(isNewValue);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
