import DashboardIcon from 'assets/image/icons/sidebar/dashboard.svg';
// import VirtualReceptionistIcon from 'assets/image/icons/sidebar/virtual-receptionist.svg';
import CallsIcon from 'assets/image/icons/sidebar/calls.svg';
import BookingCalendarIcon from 'assets/image/icons/sidebar/booking-calendar.svg';
import BusinessHoursIcon from 'assets/image/icons/sidebar/business-hours.svg';
import CustomersIcon from 'assets/image/icons/sidebar/customers.svg';
import BillingIcon from 'assets/image/icons/sidebar/billing.svg';
import ReviewsIcon from 'assets/image/icons/sidebar/reviews.svg';
import SettingsIcon from 'assets/image/icons/sidebar/settings.svg';
import NotificationsIcon from 'assets/image/icons/sidebar/notifications.svg';
import SignOutIcon from 'assets/image/icons/sidebar/sign-out.svg';

export const menuItems = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    to: '/dashboard',
    category: 'menu',
    Icon: DashboardIcon,
  },
  // {
  //   name: 'virtualReceptionist',
  //   label: 'Virtual Receptionist',
  //   to: '/virtual-receptionist',
  //   category: 'menu',
  //   Icon: VirtualReceptionistIcon,
  // },
  {
    name: 'interactions',
    label: 'Interactions',
    to: '/interactions',
    category: 'menu',
    Icon: CallsIcon,
  },
  {
    name: 'bookingCalendar',
    label: 'Booking Calendar',
    to: '/booking-calendar',
    category: 'menu',
    Icon: BookingCalendarIcon,
  },
  {
    name: 'businessHours',
    label: 'Business Hours',
    to: '/business-hours',
    category: 'menu',
    Icon: BusinessHoursIcon,
  },
  {
    name: 'customers',
    label: 'My Customers',
    to: '/customers',
    category: 'menu',
    Icon: CustomersIcon,
  },
  {
    name: 'billing',
    label: 'Billing',
    to: '/billing',
    category: 'additional',
    Icon: BillingIcon,
  },
  {
    name: 'reviews',
    label: 'Reviews',
    to: '/reviews',
    category: 'additional',
    Icon: ReviewsIcon,
  },
  {
    name: 'settings',
    label: 'Settings',
    to: '/settings',
    category: 'additional',
    Icon: SettingsIcon,
  },
  {
    name: 'notifications',
    label: 'Notifications',
    to: '/notifications',
    category: 'mobile',
    count: 3,
    Icon: NotificationsIcon,
  },
  {
    name: 'signOut',
    label: 'Sign Out',
    to: '/',
    category: 'mobile',
    Icon: SignOutIcon,
  },
];

export default menuItems;
