import React, { useContext } from 'react';

import GlobalAppContext from 'context/globalAppContext';
import { SET_SIDEBAR } from 'context/actions';
import ArrowIcon from 'assets/image/icons/app/arrow.svg';

type Props = {
  title: string | React.ReactNode,
}
const Toggle: React.FC<Props> = ({ title }) => {
  const { state: { sidebar, }, dispatch }: any = useContext(GlobalAppContext);

  const handleClick = () => {
    dispatch({
      type: SET_SIDEBAR,
      payload: { open: !sidebar.open }
    });
  };

  return (
    <div className="navbar-toggle d-flex d-sm-none d-md-none d-lg-none">
      <div
        onClick={handleClick}
        className="toggle-sidebar text-center"
      >
        <ArrowIcon />
      </div>
      <div className="navbar-title">
        {title}
      </div>
    </div>
  );
};

export default Toggle;
