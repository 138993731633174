import { Auth } from 'interfaces';

export enum EQueryKeys {
  AuthUser = 'AuthUser',
  LocalAuthUser = 'LocalAuthUser',
  CustomerClients = 'CustomerClients',
  ApiRequest = 'ApiRequest',
  Calendar = 'Calendar',
  BusinessHours = 'BusinessHours',
  Greetings = 'Greetings',
  CallLogs = 'CallLogs',
  AiVoices = 'AiVoices',
  MyPhones = 'MyPhones',
  Interactions = 'Interactions'
}

export enum EApiRoutes {
  CheckAuth = '/whoAmI',
  CustomersClients = '/customers/:customer_id/customer-clients',
  CustomerClientsDetail = '/customer-clients',
  SignUp = '/signup',
  ValidateEmail = '/validate-email',
  EmailSSO = '/sso/email',
  VerifyToken = '/users/verification/:user_id/:token',
  Login = '/users/login',
  RefreshToken = '/users/refresh',
  SendResetPasswordEmail = '/users/reset/:email',
  ResetPassword = '/users/reset-do/:user_id/:token',
  UpdateUser = '/users/:user_id',
  BusinessHours = '/customers/:customer_id/business-hours',
  BusinessHoursDetail = '/business-hours',
  CallLog = '/calls',
  Greetings = '/greetings',
  MyPhones = '/my-phone-numbers',
  PhoneSearch = '/available-phone-numbers',
  PurchasePhoneNumber = '/purchase-phone-number',
  AiVoices = '/tbd',
  Interactions = '/interactions'
};

export enum ECaldavRoutes {
  Events = '/events',
  CreateEvent = '/create-event',
  EventDetail = '/event',
  UpdateEvent = '/update-event',
  FindSlot = '/findSlot'
}

export interface IRequest {
  route: EApiRoutes | string;
  token?: string;
  method?: ERestMethods;
  body?: any;
  url?: string;
}

export interface IBasicData {
  id?: string | number;
}

export enum ERestMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export interface IMutationController {
  onCreate: (newData: any) => void;
  onUpdate: (newData: any) => void;
  onDelete: (newData: any) => void;
}

export interface IRestEndpoints {
  get: (authUser: Auth.IUser) => () => Promise<any>,
  create: (authUser: Auth.IUser) => (args: any) => Promise<any>,
  update: (authUser: Auth.IUser) => (args: any) => Promise<any>,
  delete: (authUser: Auth.IUser) => (args: any) => Promise<any>,
};

export enum ERefetchPolicies {
  Light,
  Medium,
  Heavy,
  None
}

export const RefetchPolicies = {
  [ERefetchPolicies.None]: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  },
  [ERefetchPolicies.Heavy]: {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true
  },
  [ERefetchPolicies.Medium]: {
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchOnReconnect: true
  },
  [ERefetchPolicies.Light]: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: true
  }
};


export interface ILoggerArgs {
  data?: any;
  response?: any;
  context?: any
}