import React from 'react';
import { Button } from 'components';
import { PlanWrapper } from './style';
import FreePlan from 'assets/image/icons/app/free-plan.svg';

const Plan = ({ ...props }) => (
  <PlanWrapper {...props}>
    <FreePlan />
    <div className="description">
      <h6>Your free plan is over!</h6>
      <p>Upgrade to PRO for Unlimited Storage and <span>Save 30%</span></p>
      <Button variant="secondary" round>
        Upgrade Now
      </Button>
    </div>
  </PlanWrapper>
);

export default Plan;
