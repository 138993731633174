import {
  SET_HEADER,
  SET_SIDEBAR,
  SET_SOFTPHONE
} from './actions';

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_HEADER:
      return { ...state, header: { ...state.header, ...payload }, };

    case SET_SIDEBAR:
      return { ...state, sidebar: { ...state.sidebar, ...payload }, };

    case SET_SOFTPHONE:
      return { ...state, softphone: { ...state.softphone, ...payload }, };

    default:
      return state;
  }
};

export default reducer;
