import React from 'react';
import { Nav as BNav } from 'react-bootstrap';

import Softphone from './softphone';
import Notifications from './notifications';
import UserActions from './userActions';

const Nav: React.VFC = () => {
  return (
    <BNav navbar className="flex-row d-none d-sm-flex d-md-flex d-lg-flex">
      <Softphone />
      <Notifications />
      <UserActions />
    </BNav>
  );
};

export default Nav;
