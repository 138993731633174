import React from 'react';
import classnames from 'classnames';
import { Button } from '../button';
import {
  Label, Input, Textarea, Select, Append, Title, Text, ErrorText
} from './style';

const Form = ({ children, ...rest }) => (
  <form
    {...rest}
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" />
    {children}
  </form>
);
Form.Group = ({ children, className = '', ...rest }) => (
  <div className={`form-group position-relative ${className || ''}`} {...rest}>
    {children}
  </div>
);
Form.InputGroup = ({ children, className = '', ...rest }) => (
  <div className={`form-input-group position-relative ${className || ''}`} {...rest}>
    {children}
  </div>
);
Form.Input = React.forwardRef(
  ({
    type = 'text',
    name = '',
    placeholder = '',
    id = '',
    className = '',
    isValid = null,
    isInValid = null,
    error = null,
    append = null,
    ...rest
  }: any, ref) => (
    <>
      <Form.InputGroup>
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          id={id}
          {...rest}
          ref={ref}
          className={classnames(`form-control ${className}`, {
            'is-valid': isValid,
            'is-invalid': error || isInValid,
          })}
        />
        {append && (
          <Append right className="form-control-append">
            {append}
          </Append>
        )}
      </Form.InputGroup>
      {error && (
        <ErrorText>{error}</ErrorText>
      )}
    </>
  ));
Form.Textarea = ({
  rows = 2,
  name = '',
  placeholder = '',
  id = '',
  className = '',
  isValid = null,
  isInValid = null,
  error = null,
  append = null,
  ...rest
}) => (
  <>
    <Form.InputGroup>
      <Textarea
        rows={rows}
        name={name}
        placeholder={placeholder}
        id={id}
        {...rest}
        className={classnames(`form-control ${className}`, {
          'is-valid': isValid,
          'is-invalid': error || isInValid,
        })}
      />
      {append && (
        <Append right className="form-control-append">
          {append}
        </Append>
      )}
    </Form.InputGroup>
    {error && (
      <ErrorText>{error}</ErrorText>
    )}
  </>
);
Form.Select = ({
  name,
  id = '',
  className = '',
  children,
  isValid = null,
  isInValid = null,
  error = null,
  append = null,
  ...rest
}) => (
  <>
    <Form.InputGroup>
      <Select
        name={name}
        id={id}
        {...rest}
        className={classnames(`form-control ${className}`, {
          'is-valid': isValid,
          'is-invalid': error || isInValid,
        })}
      >
        {children}
      </Select>
      {append && (
        <Append right className="form-control-append">
          {append}
        </Append>
      )}
    </Form.InputGroup>
    {error && (
      <ErrorText>{error}</ErrorText>
    )}
  </>
);
Form.Label = ({ target = '', children, ...rest }) => (
  <Label htmlFor={target} {...rest}>
    {children}
  </Label>
);
Form.Title = ({ children, ...rest }) => (
  <Title {...rest}>
    {children}
  </Title>
);
Form.Text = ({ children, ...rest }) => (
  <Text {...rest}>
    {children}
  </Text>
);
Form.ErrorText = ({ children, ...rest }) => (
  <ErrorText {...rest}>
    {children}
  </ErrorText>
);
Form.Append = ({ children, className = '', ...rest }) => (
  <Append {...rest} className={`form-control-append ${className || ''}`}>
    {children}
  </Append>
);
Form.Button = ({ value, ...rest }) => (
  <Button as="button" {...rest}>
    {value}
  </Button>
);

export default Form;
