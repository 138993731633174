import styled from 'styled-components/macro';
import { DateRangePicker } from 'react-date-range';

export const ToggleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  background: #F3F7FA;
  color: #1D1D1D;
  border-radius: 10px;
  padding: 5px 10px 4px;
  cursor: pointer;
  user-select: none;

  span {
    margin: 0 7px 0 9px;
  }
  i {
    color: #7F9CB1;
  }
`;

export const DateRangeWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #7F9CB1;
    margin-right: 12px;
  }

  .dropdown-menu {
    box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.15);
    border-color: #DCE7EF;
    border-radius: 6px;
  }
`;

export const DateRangePickerWrapper = styled(DateRangePicker)`
  .rdrDayToday .rdrDayNumber span:after {
    background: #04B3C0;
  }
`;