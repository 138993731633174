import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Loader, FullContainerLoader } from './style';

export default ({ fillContainer = false }) => (
  fillContainer ?
    <FullContainerLoader>
      <Spinner animation='grow' variant='primary' />
    </FullContainerLoader>
    : (
      <Loader>
        <Spinner animation='grow' variant='primary' />
      </Loader>
    )
);
