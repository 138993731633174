import React, { useContext, useEffect } from 'react';

import GlobalAppContext from 'context/globalAppContext';
import { SET_HEADER } from 'context/actions';
import AuthWrapper from './style';

const headerConfig = {
  headerClasses: 'no-header',
};

const AuthPageWrapper = ({ children, ...props }) => {
  const { dispatch } = useContext(GlobalAppContext);

  useEffect(() => {
    dispatch({
      type: SET_HEADER,
      payload: { ...headerConfig }
    });
  }, [dispatch]);

  return (
    <AuthWrapper>
      <AuthWrapper.Content {...props}>{children}</AuthWrapper.Content>
    </AuthWrapper>
  );
};

export default AuthPageWrapper;
