import React, { useState, useCallback, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useOutsideHandler } from 'components/hooks';
import { searchData } from 'data/pages/app/dashboard';
import BusinessIcon from 'assets/image/icons/app/business.svg';
import AddressIcon from 'assets/image/icons/app/address.svg';

const Search: React.VFC = () => {
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchResultRef = useRef(null);

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  useOutsideHandler({
    ref: searchResultRef,
    callback: handleOutsideClick
  });

  const handleDebounceFn = (props: string) => {
    if (props) {
      setItems(searchData);
    } else {
      setItems([]);
    }
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 500), []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <Form className="navbar-search w-100 d-none d-md-flex d-lg-flex">
      <Form.Group className="h-100">
        <InputGroup hasValidation>
          <InputGroup.Text id="inputGroupPrepend">
            <i className="fa fa-search" />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search anything..."
            aria-describedby="inputGroupPrepend"
            value={query}
            onChange={handleChange}
            onFocus={() => setIsOpen(true)}
            required
          />
        </InputGroup>
      </Form.Group>
      {items.length > 0 && isOpen && (
        <div className="navbar-search__result" ref={searchResultRef}>
          {items.map((item, index) => (
            <div className="navbar-search__result-item" key={index}>
              <div className="navbar-search__result-item-avatar">
                <img src={item.avatar} alt="Avatar" />
              </div>
              <div className="navbar-search__result-item-content">
                <h4>{item.name}</h4>
                <p><BusinessIcon /> {item.company}</p>
                <p><AddressIcon /> {item.address}</p>
              </div>
            </div>
          ))}
          <div className="navbar-search__result-more">
            View more results
          </div>
        </div>
      )}
    </Form>
  );
};

export default Search;
