import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { addDays } from 'date-fns';
import {
  ToggleWrapper,
  DateRangeWrapper,
  DateRangePickerWrapper,
} from './style';
import CalendarIcon from 'assets/image/icons/app/calendar.svg';

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  <ToggleWrapper
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ToggleWrapper>
));

const Menu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    );
  },
);

const DateRange = ({ isLabelEnabled = true, ...props }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  return (
    <DateRangeWrapper {...props}>
      {isLabelEnabled && <span>Short by</span>}
      <Dropdown>
        <Dropdown.Toggle as={Toggle}>
          <CalendarIcon />
          <span>Last 7 days</span>
          <i className="fa fa-chevron-down" />
        </Dropdown.Toggle>

        <Dropdown.Menu as={Menu}>
          <DateRangePickerWrapper
            onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            color={'#04B3C0'}
            rangeColors={['#04B3C0']}
          />
        </Dropdown.Menu>
      </Dropdown>
    </DateRangeWrapper>
  );
};

export default DateRange;
