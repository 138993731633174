export interface IShape {
  [formKey: string]: IInput;
}

export interface IInput {
  required?: boolean;
  default?: any;
  validator?: IValidator;
}

export interface IValidator {
  func: (param: any) => boolean;
  errorMsg: string;
}

export enum EGoogleComponentTypes {
  StreetNumber = 'street_number',
  Route = 'route',
  City = 'locality',
  State = 'administrative_area_level_1',
  Country = 'country',
  ZipCode ='postal_code'
}